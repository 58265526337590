import * as Yup from "yup";
import { GrupoAcessoFormModel } from "../../../models/GrupoAcesso";

export const formGrupoAcessoSchema: Yup.SchemaOf<
  Omit<GrupoAcessoFormModel, "acessos">
> = Yup.object().shape({
  id: Yup.string().notRequired(),
  nome: Yup.string()
    .required("Campo obrigatório")
    .max(50, "Informe até 50 caracteres"),
  codigo: Yup.string().required("Campo obrigatório"),
  descricao: Yup.string()
    .required("Campo obrigatório")
    .max(200, "Informe até 200 caracteres"),
  aplicacaoId: Yup.string().notRequired(),
  tenantId: Yup.string()
    .required("TenantId necessário")
    .test(
      "tenantValido",
      "É necessário selecionar um Tenant ao lado",
      (val) => {
        if (!val) return false;
        if (val === "00000000-0000-0000-0000-000000000000") return false;
        return true;
      }
    ),
});
