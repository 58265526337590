import { useMsal } from "@azure/msal-react";
import { OptSideLayout } from "@optsol/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthenticationContext } from "../../contexts/authentication/authenticationContext";
import {
  PainelPaths,
  PainelRoutes,
} from "../../routes/administracao/Painel.routes";
import { sections } from "./App.sections";

function App() {
  const history = useHistory();
  const { instance } = useMsal();
  const {
    state: { userInfo },
    carregarUserInfo,
  } = useAuthenticationContext();

  function onManageProfileHandler() {
    history.push(PainelPaths.MeuPerfil);
  }

  useEffect(() => {
    carregarUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptSideLayout
      sections={sections}
      routes={PainelRoutes}
      onLogout={() => {
        instance.logoutRedirect();
      }}
      onManageProfile={onManageProfileHandler}
      profile={{
        email: userInfo?.data?.email ?? "-",
        name: userInfo?.data?.name ?? "-",
        avatarSrc: undefined,
      }}
      appBarConfig={{
        hideLinkDescription: true,
      }}
      version="Versão 1.0"
    />
  );
}

export default App;
