import { UserInfo } from '../../msal/UserInfo';
import { useApi } from '../../utils/api';

export function useAuthenticationService() {
  const api = useApi();
  const baseApi = '/api/usuario';

  async function getUserInfo() {
    const result = await api.getRoot<UserInfo>(`${baseApi}/token/info`);
    return result;
  }

  return { getUserInfo };
}
