import { UserInfo } from '../../msal/UserInfo';
import { LoadableData } from '../loadableData';

export interface AuthenticationState {
  userInfo: LoadableData<UserInfo | undefined>;
}

export const AUTHENTICATION_INITIAL_STATE: AuthenticationState = {
  userInfo: { data: undefined, loading: false },
};
