import { Button, TextField } from "@mui/material";
import { OptDialog, OptDialogActions } from "@optsol/react";
import { useSnackbar } from "notistack";
import { ChangeEvent, createRef, KeyboardEvent, useState } from "react";
import NumberFormat from "react-number-format";
import { v4 as uuidv4 } from "uuid";
import * as S from "./styles";

export type TiposNodeAcessoControlado =
  | "dominioAcesso"
  | "grupoAcesso"
  | "acesso";

interface Props {
  parentId: string | null;
  open: boolean;
  tipo: TiposNodeAcessoControlado;
  fechar: () => void;
  criar: (
    parentId: string | null,
    tipo: TiposNodeAcessoControlado,
    treeControlId: string,
    nome: string,
    acessoPaiId: string,
    aplicacaoId: string,
    codigo: string,
    level: number,
    descricao?: string
  ) => void;
  verificarCodigoRepetido: (codigo: string) => boolean;
  reunirCodigos: () => string[];
}

export const CriacaoNodeAcessoControladoDialog = ({
  parentId,
  open,
  fechar,
  criar,
  tipo,
  verificarCodigoRepetido,
  reunirCodigos,
}: Props) => {
  const [nome, setNome] = useState("");

  const nomeRef = createRef<HTMLInputElement>();
  const descricaoRef = createRef<HTMLInputElement>();
  const codigoRef = createRef<HTMLInputElement>();

  const { enqueueSnackbar } = useSnackbar();

  let titulo = "Criar novo domínio de acesso";
  let botaoCriarTitulo = "Criar domínio de acesso";

  if (tipo === "grupoAcesso") {
    titulo = "Criar novo grupo de acesso";
    botaoCriarTitulo = "Criar grupo de acesso";
  } else if (tipo === "acesso") {
    titulo = "Criar novo acesso";
    botaoCriarTitulo = "Criar acesso";
  }

  function criarHandler() {
    if (nomeRef.current && nomeRef.current.value) {
      if (descricaoRef.current && descricaoRef.current.value.length > 100) {
        enqueueSnackbar("Descrição deve ter até 100 caracteres!", {
          variant: "error",
        });
      } else {
        if (codigoRef.current && codigoRef.current.value.length > 1) {
          if (verificarCodigoRepetido(codigoRef.current.value)) {
            enqueueSnackbar("Códgo já utilizado!", {
              variant: "error",
            });
          } else {
            const id = uuidv4();
            criar(
              parentId ?? null,
              tipo,
              id,
              nomeRef.current.value,
              "",
              "",
              codigoRef.current.value,
              0,
              descricaoRef.current?.value
            );
          }
        } else {
          enqueueSnackbar("Informe um código!", {
            variant: "error",
          });
        }
      }
    } else {
      enqueueSnackbar("Informe um nome!", { variant: "error" });
    }
  }

  function verificarTeclaPressionadaEnter(
    event: KeyboardEvent<HTMLDivElement>
  ) {
    if (event.key === "Enter") {
      criarHandler();
    }
  }

  function alterarNome(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const reg = /[^a-z|-]/;

    let novoNome = e.currentTarget.value;
    if (novoNome) novoNome = novoNome.toLocaleLowerCase().trim();

    if (reg.test(novoNome)) {
      enqueueSnackbar(
        "Somente letras mínusculas e traços (-) são permitidos!",
        {
          variant: "info",
          preventDuplicate: true,
        }
      );
    }

    novoNome = novoNome.replace(reg, "");

    setNome(novoNome);
  }

  const renderPrefixo = (tipo: string) => {
    let codigoA: string[] = [];
    let codigoB: string[] = [];
    let codigoC: string[] = [];
    const codigosArray = reunirCodigos();

    codigosArray.map((codigo) => {
      codigo.charAt(0) === "A" && codigoA.push(codigo);
      codigo.charAt(0) === "B" && codigoB.push(codigo);
      codigo.charAt(0) === "C" && codigoC.push(codigo);
    });

    switch (tipo) {
      case "dominioAcesso":
        const sortedA = codigoA.sort((a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        const ultimoCodigoA = sortedA[sortedA.length - 1];
        const numeroCodigoA = ultimoCodigoA.substring(1);
        const proximoNumeroA = (+numeroCodigoA + 1).toString();

        return { prefixo: "A", proximoNumero: proximoNumeroA };
      case "grupoAcesso":
        const sortedB = codigoB.sort((a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        const ultimoCodigoB = sortedB[sortedB.length - 1];
        const numeroCodigoB = ultimoCodigoB.substring(1);
        const proximoNumeroB = (+numeroCodigoB + 1).toString();

        return { prefixo: "B", proximoNumero: proximoNumeroB };
      case "acesso":
        const sortedC = codigoC.sort((a, b) => {
          return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        const ultimoCodigoC = sortedC[sortedC.length - 1];
        const numeroCodigoC = ultimoCodigoC.substring(1);
        const proximoNumeroC = (+numeroCodigoC + 1).toString();

        return { prefixo: "C", proximoNumero: proximoNumeroC };
      default:
        break;
    }
  };

  return (
    <OptDialog
      title={titulo}
      open={open}
      onClose={fechar}
      aria-labelledby="form-dialog-title"
    >
      <S.StyledDialogContent>
        <TextField
          autoFocus
          variant="outlined"
          id="nome"
          label="Nome"
          fullWidth
          inputRef={nomeRef}
          value={nome}
          style={{ minWidth: 300 }}
          onKeyDown={verificarTeclaPressionadaEnter}
          onChange={alterarNome}
        />

        <NumberFormat
          defaultValue={renderPrefixo(tipo)?.proximoNumero}
          prefix={renderPrefixo(tipo)?.prefixo}
          mask="###"
          type="text"
          customInput={TextField}
          allowEmptyFormatting
          variant="outlined"
          id="codigo"
          name="codigo"
          label="Código"
          fullWidth
          inputProps={{ maxLength: 3 }}
          inputRef={codigoRef}
          style={{ minWidth: 2 }}
          onKeyDown={verificarTeclaPressionadaEnter}
        />

        <TextField
          variant="outlined"
          id="descricao"
          label="Descrição"
          fullWidth
          inputRef={descricaoRef}
          style={{ minWidth: 300 }}
          onKeyDown={verificarTeclaPressionadaEnter}
        />
      </S.StyledDialogContent>

      <OptDialogActions>
        <Button onClick={fechar}>Cancelar</Button>
        <Button onClick={criarHandler} color="primary">
          {botaoCriarTitulo}
        </Button>
      </OptDialogActions>
    </OptDialog>
  );
};
