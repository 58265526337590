import { OptSelectionOption } from "@optsol/react";
import { useEffect } from "react";
import { useGrupoAcessoContext } from "../../../contexts/grupoAcesso/grupoAcessoContext";
import { TenantsGrupoAcessoModel } from "../../../models/GrupoAcesso";
import { useAplicacaoService } from "../../../services/aplicacao.service";
import { RotaAtualProps } from "../../../shared/types/RotaAtualProps";
import { ListaGruposAcessoSidebarView } from "./ListaGruposAcessoSidebarView";

interface Props extends RotaAtualProps {
  aplicacaoId: string;
}

export const ListaGruposAcessoSidebar = ({ rotaAtual, aplicacaoId }: Props) => {
  const {
    state: { lista },
    listarGruposAcessoPorAplicacao,
    resetarListaGrupoAcessoPorAplicacao,
    atualizarContextoTenantId,
  } = useGrupoAcessoContext();
  const { obterTenantAplicacaoAcesso } = useAplicacaoService();

  async function obterTenants() {
    const response = await obterTenantAplicacaoAcesso(aplicacaoId);
    const data: OptSelectionOption[] = response.map(
      (x: TenantsGrupoAcessoModel) => ({
        value: x.id!,
        label: x.nome,
      })
    );
    return data;
  }

  function selecionarTenantGrupoAcesso(tenantId: string) {
    listarGruposAcessoPorAplicacao(aplicacaoId, tenantId);
    atualizarContextoTenantId(tenantId);
  }

  useEffect(() => {
    resetarListaGrupoAcessoPorAplicacao();
    // listarGruposAcessoPorAplicacao(
    //   aplicacaoId,
    //   "00000000-0000-0000-0000-000000000000"
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListaGruposAcessoSidebarView
      gruposAcesso={lista.data}
      loading={lista.loading}
      rotaAtual={rotaAtual}
      aplicacaoId={aplicacaoId}
      obterTenants={obterTenants}
      selecionarTenantGrupoAcesso={selecionarTenantGrupoAcesso}
    />
  );
};
