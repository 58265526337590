import { PublicClientApplication } from '@azure/msal-browser';

// optsolsso.onmicrosoft.com/b2c_1_login/
export const MsalConfig = {
  instance: process.env.REACT_APP_BASE_B2C_INSTANCE!,
  tenant: process.env.REACT_APP_BASE_B2C_TENANT!,
  signin_policy: process.env.REACT_APP_BASE_B2C_SIGNIN_POLICY!,
  clientid: process.env.REACT_APP_BASE_B2C_CLIENTID!,
  cache_location: process.env.REACT_APP_BASE_B2C_CACHE_LOCATION!,
  scopes: [process.env.REACT_APP_BASE_B2C_SCOPES!],
  redirect_uri: process.env.REACT_APP_BASE_B2C_REDIRECT_URI!,
  post_logout_redirect_uri: process.env.REACT_APP_BASE_B2C_POST_LOGOUT_REDIRECT_URI!,
};

export const pca = new PublicClientApplication({
  auth: {
    clientId: MsalConfig.clientid,
    postLogoutRedirectUri: MsalConfig.post_logout_redirect_uri,
    redirectUri: MsalConfig.redirect_uri,
    authority: `${MsalConfig.instance}/${MsalConfig.tenant}/${MsalConfig.signin_policy}`,
    knownAuthorities: [MsalConfig.instance],
  },
  system: {
    tokenRenewalOffsetSeconds: 600,
  },
});
