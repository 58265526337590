import { AcessoFormModel, AcessoClaimsModel } from "../models/Acesso/Acesso";

export function deepCopy<T>(data: T): T {
  return JSON.parse(JSON.stringify(data)) as T;
}

export function compararArrays<T>(array1: T[], array2: T[]) {
  const array2Sorted = array2.slice().sort();

  return (
    array1.length === array2.length &&
    array1
      .slice()
      .sort()
      .every((value, index) => {
        return value === array2Sorted[index];
      })
  );
}

function toPascalCase(str: string) {
  return (str.match(/[a-zA-Z0-9]+/g) || [])
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join("");
}

export function gerarClaimsModel(data: AcessoFormModel[], back?: boolean) {
  const acessosA = data.map((acesso) => {
    return {
      codigo: acesso.codigo,
      nome: acesso.nome,
      id: acesso.id,
    };
  });

  const acessosB = data.map((acesso) => {
    let acessos: {
      codigo: string;
      nome: string;
      id: string;
      paiId: string;
    }[] = [];
    if (acesso.acessosFilhos && acesso.acessosFilhos.length) {
      acesso.acessosFilhos.map((acessoFilho: AcessoFormModel) => {
        return acessos.push({
          codigo: acessoFilho.codigo,
          nome: !back
            ? acessoFilho.nome
            : "public const string " + acessoFilho.nome.toUpperCase(),
          id: acessoFilho.id!,
          paiId: acessoFilho.acessoPaiId,
        });
      });
    }
    return acessos;
  });

  const acessosC = data.map((acesso) => {
    let acessos: {
      codigo: string;
      nome: string;
      id: string;
      paiId: string;
    }[] = [];
    if (acesso.acessosFilhos && acesso.acessosFilhos.length) {
      acesso.acessosFilhos.map((acessoFilho: AcessoFormModel) => {
        if (acessoFilho.acessosFilhos && acessoFilho.acessosFilhos.length) {
          acessoFilho.acessosFilhos.map((acessoNeto: AcessoFormModel) => {
            return acessos.push({
              codigo: acessoNeto.codigo,
              nome: !back
                ? `${acessoFilho.nome}__${acessoNeto.nome}`
                : "public const string " +
                  `${acessoFilho.nome.toUpperCase()}__${acessoNeto.nome.toUpperCase()}`,
              id: acessoNeto.id!,
              paiId: acessoNeto.acessoPaiId,
            });
          });
        }
      });
    }
    return acessos;
  });

  function agruparAcessosFilhos(paiId: string) {
    let acessosObject: AcessoClaimsModel = {};

    acessosA.forEach((acessoA) => {
      if (paiId === acessoA.id) {
        acessosObject[
          !back
            ? acessoA.nome
            : "public const string " + acessoA.nome.toUpperCase()
        ] = acessoA.codigo;
      }
    });

    acessosB.forEach((acessoB) => {
      acessoB.forEach((itemB) => {
        if (itemB.paiId === paiId) {
          acessosObject[itemB.nome] = itemB.codigo;

          acessosC.forEach((acessoC) => {
            acessoC.forEach((itemC) => {
              if (itemC.paiId === itemB.id) {
                acessosObject[itemC.nome] = itemC.codigo;
              }
            });
          });
        }
      });
    });

    return acessosObject;
  }

  let nomesA: string[] = [];
  let codigosArray: AcessoClaimsModel[] = [];

  acessosA.forEach((item) => {
    item.id && nomesA.push(item.nome);
    item.id && codigosArray.push(agruparAcessosFilhos(item.id));
  });

  const dataFinal = codigosArray.map((claim, index) => {
    return !back
      ? "export enum " +
          toPascalCase(nomesA[index]) +
          "Claims " +
          JSON.stringify(claim)
            .replace(/"([^"]+)":/g, "$1:")
            .replace(/[:]/g, "=")
            .replace(/[-]/g, "_")
            .replace(/[\"]/g, "'")
            .toUpperCase()
      : "public class " +
          toPascalCase(nomesA[index]) +
          "Claims " +
          JSON.stringify(claim)
            .replace(/"([^"]+)":/g, "$1:")
            .replace(/[:]/g, "=")
            .replace(/[-]/g, "_")
            .replace(/[,]/g, ";")
            .replace(/[\"]/g, "'")
            .replaceAll(/[}]/g, ";}");
  });

  return JSON.stringify(!back ? dataFinal.join("; ") : dataFinal.join(" "));
}

export function gerarEnumModel(data: AcessoFormModel[]) {
  const acessosA = data.map((acesso) => {
    return {
      codigo: acesso.codigo,
      nome: acesso.nome,
      id: acesso.id,
    };
  });

  const acessosB = data.map((acesso) => {
    let acessos: {
      codigo: string;
      nome: string;
      id: string;
      paiId: string;
    }[] = [];
    if (acesso.acessosFilhos && acesso.acessosFilhos.length) {
      acesso.acessosFilhos.map((acessoFilho: AcessoFormModel) => {
        return acessos.push({
          codigo: acessoFilho.codigo,
          nome: acessoFilho.nome,
          id: acessoFilho.id!,
          paiId: acessoFilho.acessoPaiId,
        });
      });
    }
    return acessos;
  });

  const acessosC = data.map((acesso) => {
    let acessos: {
      codigo: string;
      nome: string;
      id: string;
      paiId: string;
    }[] = [];
    if (acesso.acessosFilhos && acesso.acessosFilhos.length) {
      acesso.acessosFilhos.map((acessoFilho: AcessoFormModel) => {
        if (acessoFilho.acessosFilhos && acessoFilho.acessosFilhos.length) {
          acessoFilho.acessosFilhos.map((acessoNeto: AcessoFormModel) => {
            return acessos.push({
              codigo: acessoNeto.codigo,
              nome: `${acessoFilho.nome}__${acessoNeto.nome}`,
              id: acessoNeto.id!,
              paiId: acessoNeto.acessoPaiId,
            });
          });
        }
      });
    }
    return acessos;
  });

  function agruparAcessosFilhos(paiId: string) {
    let acessosObject: AcessoClaimsModel = {};

    acessosA.forEach((acessoA) => {
      if (paiId === acessoA.id) {
        acessosObject[acessoA.nome] = `('${
          acessoA.codigo
        }', '${acessoA.nome.toUpperCase()}')`;
      }
    });

    acessosB.forEach((acessoB) => {
      acessoB.forEach((itemB, index) => {
        if (itemB.paiId === paiId) {
          acessosObject[itemB.nome] = `('${
            itemB.codigo
          }', '${itemB.nome.toUpperCase()}')${
            index === acessoB.length - 1 ? "," : ""
          }`;

          acessosC.forEach((acessoC) => {
            acessoC.forEach((itemC, index) => {
              if (itemC.paiId === itemB.id) {
                acessosObject[itemC.nome] = `('${
                  itemC.codigo
                }', '${itemC.nome.toUpperCase()}')${
                  index === acessoC.length - 1 ? "," : ""
                }`;
              }
            });
          });
        }
      });
    });

    return acessosObject;
  }

  let nomesA: string[] = [];
  let codigosArray: AcessoClaimsModel[] = [];

  acessosA.forEach((item) => {
    item.id && nomesA.push(item.nome);
    item.id && codigosArray.push(agruparAcessosFilhos(item.id));
  });

  const dataFinal = codigosArray.map((claim, index) => {
    return JSON.stringify(claim)
      .replace(/"([^"]+)":/g, "$1:")
      .replace(/[-]/g, "_")
      .replace(/[;]/g, ",")
      .replace(/[:]/g, "")
      .replace(/[\"]/g, "")
      .replace(/,{2,}/g, ",")
      .replace(/[{]/g, "")
      .replace(/[}]/g, "");
  });

  const stringFinal = `const ClaimsEnum(this.code, this.name); final String code; final String name; @override String toString() => 'ClaimsEnum($code, $name)'; }`;

  return JSON.stringify(
    "enum ClaimsEnum { " + dataFinal.join(" ") + ";" + stringFinal
  );
}
