import { Grid, TextField } from "@mui/material";
import { OptAvatar } from "@optsol/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Perfil } from "../../models/Perfil";
import { FooterToolbar } from "../../shared/components/FooterToolbar";
import { CommonButton } from "../../shared/components/Buttons/CommonButton";
import * as S from "./styles";

interface Props {
  data: Perfil;
}

export const FormMeuPerfil = ({ data }: Props) => {
  const { control, handleSubmit } = useForm<Perfil>({
    defaultValues: data,
  });

  function salvar(data: Perfil) {}

  return (
    <S.Container>
      <OptAvatar
        profile={{
          email: data.email,
          name: data.nome,
          avatarSrc: data.avatarSrc,
        }}
        size={100}
      />

      <S.Titulo>Dados gerais</S.Titulo>

      <form onSubmit={handleSubmit(salvar)}>
        <S.FormGrid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="nome"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Nome"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Email"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name="contato"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Contato"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <S.Titulo>Cargo</S.Titulo>
          </Grid>

          <Grid item sm={12}>
            <Controller
              name="aplicacaoId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Aplicação"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Controller
              name="grupoAcessoId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Grupo de acesso"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
        </S.FormGrid>

        <FooterToolbar>
          <CommonButton variant="text">Cancelar</CommonButton>

          <CommonButton type="submit" color="primary">
            Salvar alterações
          </CommonButton>
        </FooterToolbar>
      </form>
    </S.Container>
  );
};
