import { mdiDelete, mdiDeleteOutline } from "@mdi/js";
import {
  OptConfirmationDialog,
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
  OptSelectionOption,
} from "@optsol/react";
import { useRef, useState } from "react";
import { PerfilAcessoSearchResponseModel } from "../../../models/PerfilAcesso";
import { UsuarioSearchResponseModel } from "../../../models/Usuario";
import { Colors } from "../../../shared/colors";
import { PaginatedSearchRequest } from "../../../shared/types/SearchRequest";
import { UsuarioSearch } from "../ListaUsuario/UsuarioSearch";
import * as S from "./styles";

const options: OptGridOptions = {
  search: false,
  toolbar: true,
  pageSize: 5,
};

interface Props {
  carregarGrid: (
    query: OptGridRequest,
    UsuariosSearchRequest?: PaginatedSearchRequest
  ) => Promise<OptGridResponse<PerfilAcessoSearchResponseModel>>;
  adicionar: (ids: string[]) => Promise<void>;
  remover: (id: string) => Promise<void>;
  carregarOptionsUsuario: (termo: string) => Promise<OptSelectionOption[]>;
}

export const GridUsuario = ({
  carregarGrid,
  adicionar,
  remover,
  carregarOptionsUsuario,
}: Props) => {
  const [confirmacaoExclusaoAberta, setConfirmacaoExclusaoAberta] =
    useState<string>();

  const ref = useRef<OptGridRef>();

  const columns: OptGridColumn<UsuarioSearchResponseModel>[] = [
    { title: "id", field: "id", align: "left", hidden: true },
    {
      title: "Login",
      field: "login",
      align: "left",
    },
    {
      title: "Usuário",
      field: "nome",
      align: "left",
    },
  ];

  async function adicionarUsuarios(ids: string[]) {
    await adicionar(ids);
    ref?.current?.refresh();
  }

  function confirmarExclusao(rowData: UsuarioSearchResponseModel) {
    setConfirmacaoExclusaoAberta(rowData.id);
  }

  async function removerUsuario() {
    await remover(confirmacaoExclusaoAberta!);
    ref?.current?.refresh();
    setConfirmacaoExclusaoAberta(undefined);
  }

  return (
    <>
      <UsuarioSearch
        salvar={adicionarUsuarios}
        carregar={carregarOptionsUsuario}
      />

      <S.GridContainerSemTitle>
        <OptGrid
          title=""
          ref={ref as any}
          columns={columns}
          data={carregarGrid}
          options={options}
          actionsPosition={"end"}
          actions={[
            (rowData) => ({
              icon: { path: mdiDeleteOutline, color: Colors.red },
              tooltip: "Remover Usuário",
              onClick: (e) => {
                e.stopPropagation();
                confirmarExclusao(rowData);
              },
            }),
          ]}
        />
      </S.GridContainerSemTitle>

      <OptConfirmationDialog
        open={!!confirmacaoExclusaoAberta}
        title="Excluir Usuário"
        confirmationButtonText="Excluir"
        onCancel={() => setConfirmacaoExclusaoAberta(undefined)}
        onClose={() => setConfirmacaoExclusaoAberta(undefined)}
        onConfirm={removerUsuario}
        icon={{ path: mdiDelete, color: Colors.red }}
      >
        Deseja confirmar a exclusão deste usuário?
      </OptConfirmationDialog>
    </>
  );
};
