import {
  mdiCheckboxBlankOutline,
  mdiCheckboxOutline,
  mdiChevronDown,
  mdiChevronRight,
  mdiCollapseAllOutline,
  mdiExpandAllOutline,
  mdiLeaf,
  mdiMinusBoxOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { useEffect, useState } from "react";
import CheckboxTree, { Node } from "react-checkbox-tree";
import { AcessoFormModel } from "../../../../models/Acesso/Acesso";
import { CommonButton } from "../../../../shared/components/Buttons/CommonButton";
import { TreeContainer } from "../../../../shared/components/Tree/styles";
import { compararArrays, deepCopy } from "../../../../utils/functions";
import { AcessoControladoNode } from "./AcessoControladoNode";
import {
  CriacaoNodeAcessoControladoDialog,
  TiposNodeAcessoControlado,
} from "./CriacaoNodeAcessoControladoDialog";

interface NodeLabel {
  props: { codigoAlias: string };
}

interface Props {
  acessos: AcessoFormModel[];
  alterarAcessos: (data: AcessoFormModel[]) => void;
  selecionavel?: boolean;
}

export const AcessoControladoTree = ({
  acessos,
  alterarAcessos,
  selecionavel = false,
}: Props) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [checked, setChecked] = useState<string[]>([]);

  const [criacaoNode, setCriandoTela] = useState<{
    open: boolean;
    tipo: TiposNodeAcessoControlado;
    parentId: string | null;
  }>({
    open: false,
    tipo: "dominioAcesso",
    parentId: null,
  });

  function fecharModalCriacaoTela() {
    setCriandoTela({ tipo: "dominioAcesso", parentId: null, open: false });
  }

  function abrirModalCriacaoTela(
    tipo?: TiposNodeAcessoControlado,
    parentId?: string | null
  ) {
    setCriandoTela({
      tipo: tipo ?? "dominioAcesso",
      parentId: parentId ?? null,
      open: true,
    });
  }

  const reunirCodigos = () => {
    let codigosArray: string[] = [];
    if (nodes.length) {
      nodes.forEach((node) => {
        if (node.children && node.children.length) {
          node.children.forEach((node) => {
            if (node.children && node.children.length) {
              node.children.forEach((node) => {
                const label = node.label as NodeLabel;
                codigosArray.push(label.props.codigoAlias);
              });
            }
            const label = node.label as NodeLabel;
            codigosArray.push(label.props.codigoAlias);
          });
        }
        const label = node.label as NodeLabel;
        codigosArray.push(label.props.codigoAlias);
      });
    }

    return codigosArray;
  };

  const verificarCodigoRepetido = (codigo: string) => {
    const codigosArray = reunirCodigos();

    if (codigosArray.some((item) => item === codigo)) {
      return true;
    } else return false;
  };

  function removerNodesInexistentes(todosIds: string[]) {
    let novoExpanded = [...expanded];

    novoExpanded = novoExpanded.filter((x) => todosIds.some((y) => y === x));

    const haDiferenca = compararArrays(expanded, novoExpanded);

    if (haDiferenca) {
      setExpanded(novoExpanded);
    }
  }

  function construirNodes(telas: AcessoFormModel[]) {
    // TODO recursividade
    const todosIds: string[] = [];

    const novosNodes: Node[] = telas.map((tela) => {
      if (tela.id) {
        tela.treeControlId = tela.id;
        todosIds.push(tela.treeControlId);
      }

      const telaNode: Node = {
        value: tela.treeControlId,
        label: (
          <AcessoControladoNode
            key={tela.treeControlId}
            id={tela.treeControlId}
            tipo="dominioAcesso"
            label={tela.nome}
            codigoAlias={tela.codigo}
            descricao={tela.descricao}
            adicionar={(id) => abrirModalCriacaoTela("grupoAcesso", id)}
            excluir={excluirNodeTelaAcesso}
            selecionavel={selecionavel}
          />
        ),
        showCheckbox: selecionavel,
      };

      if (tela.acessosFilhos.length > 0) {
        telaNode.children = tela.acessosFilhos.map((grupoAcesso) => {
          if (grupoAcesso.id) {
            grupoAcesso.treeControlId = grupoAcesso.id;
            todosIds.push(grupoAcesso.treeControlId);
          }

          const grupoAcessoNode: Node = {
            value: grupoAcesso.treeControlId,
            label: (
              <AcessoControladoNode
                key={grupoAcesso.treeControlId}
                id={grupoAcesso.treeControlId}
                tipo="grupoAcesso"
                label={grupoAcesso.nome}
                codigoAlias={grupoAcesso.codigo}
                descricao={grupoAcesso.descricao}
                adicionar={(id) => abrirModalCriacaoTela("acesso", id)}
                selecionavel={selecionavel}
                excluir={excluirNodeTelaAcesso}
              />
            ),
            showCheckbox: selecionavel,
            children: undefined,
          };

          if (grupoAcesso.acessosFilhos.length > 0) {
            grupoAcessoNode.children = grupoAcesso.acessosFilhos.map(
              (claim: AcessoFormModel) => {
                if (claim.id) {
                  claim.treeControlId = claim.id;
                  todosIds.push(claim.treeControlId);
                }

                const claimNode: Node = {
                  value: claim.treeControlId,
                  label: (
                    <AcessoControladoNode
                      key={claim.treeControlId}
                      id={claim.treeControlId}
                      tipo="acesso"
                      label={claim.nome}
                      codigoAlias={claim.codigo}
                      descricao={claim.descricao}
                      excluir={excluirNodeTelaAcesso}
                      selecionavel={selecionavel}
                    />
                  ),
                  showCheckbox: selecionavel,
                };

                return claimNode;
              }
            );
          }

          return grupoAcessoNode;
        });
      }

      return telaNode;
    });

    removerNodesInexistentes(todosIds);
    setNodes(novosNodes);
  }

  function criarNodeTelaAcesso(
    parentId: string | null,
    tipo: TiposNodeAcessoControlado,
    treeControlId: string,
    nome: string,
    acessoPaiId: string,
    aplicacaoId: string,
    codigo: string,
    level: number,
    descricao?: string
  ) {
    fecharModalCriacaoTela();
    const copiaTelas = deepCopy(acessos);

    if (tipo === "dominioAcesso") {
      copiaTelas.push({
        treeControlId,
        nome,
        descricao,
        acessosFilhos: [],
        acessoPaiId,
        aplicacaoId,
        codigo,
        level,
      });
    } else {
      copiaTelas.forEach((tela) => {
        if (tela.treeControlId === parentId) {
          tela.acessosFilhos.push({
            treeControlId,
            nome,
            descricao,
            acessosFilhos: [],
            acessoPaiId,
            aplicacaoId,
            codigo,
            level,
          });
        } else {
          tela.acessosFilhos.forEach((grupoAcesso) => {
            if (grupoAcesso.treeControlId === parentId) {
              grupoAcesso.acessosFilhos.push({
                treeControlId,
                nome,
                descricao,
                acessosFilhos: [],
                acessoPaiId,
                aplicacaoId,
                codigo,
                level,
              });
            }
          });
        }
      });
    }

    if (parentId) {
      const deveExpandirParent = !expanded.some((x) => x === parentId);

      if (deveExpandirParent) {
        setExpanded([...expanded, parentId]);
      }
    }

    alterarAcessos!(copiaTelas);
  }

  function excluirNodeTelaAcesso(treeControlId: string) {
    let copiaTelas = deepCopy(acessos);

    copiaTelas = copiaTelas.filter((x) => x.treeControlId !== treeControlId);

    copiaTelas.forEach((tela) => {
      tela.acessosFilhos = tela.acessosFilhos.filter(
        (grupoAcesso) => grupoAcesso.treeControlId !== treeControlId
      );

      tela.acessosFilhos.forEach((grupoAcesso) => {
        grupoAcesso.acessosFilhos = grupoAcesso.acessosFilhos.filter(
          (claim: AcessoFormModel) => claim.treeControlId !== treeControlId
        );
      });
    });

    alterarAcessos!(copiaTelas);
  }

  function expandir(ids: string[]) {
    setExpanded(ids);
  }

  function capturarSelecoes(acessos: AcessoFormModel[]) {
    let novoChecked: string[] = [];

    acessos.forEach((a) => {
      if (a.temAcesso) novoChecked.push(a.treeControlId);

      if (a.acessosFilhos && a.acessosFilhos.length > 0) {
        novoChecked = novoChecked.concat(capturarSelecoes(a.acessosFilhos));
        if (existeFilhosComAcesso(a.acessosFilhos)) {
          a.temAcesso = true;
        }
      }
    });

    return novoChecked;
  }

  function existeFilhosComAcesso(acessos: AcessoFormModel[]) {
    const a = acessos.map((x) => x.temAcesso === true);
    return a;
  }

  function redefinirSelecaoNodes() {
    const novoChecked = capturarSelecoes(acessos);

    setChecked(novoChecked);
  }

  function selecionarAcessos(
    acessosAtuais: AcessoFormModel[],
    novoChecked: string[]
  ) {
    acessosAtuais = acessosAtuais.map((acesso) => {
      acesso.temAcesso = novoChecked.some((x) => x === acesso.id);

      if (acesso.acessosFilhos) {
        acesso.acessosFilhos = selecionarAcessos(
          acesso.acessosFilhos,
          novoChecked
        );
      }
      return acesso;
    });

    return acessosAtuais;
  }

  function redefinirSelecaoAcessos(novoChecked: string[]) {
    let copiaAcessos = deepCopy(acessos);
    copiaAcessos = selecionarAcessos(copiaAcessos, novoChecked);
    alterarAcessos(copiaAcessos);
  }

  function alterarChecked(novoChecked: string[]) {
    setChecked(novoChecked);
    redefinirSelecaoAcessos(novoChecked);
    acessos.forEach((element) => {
      element.temAcesso = element.acessosFilhos.some(
        (x) => x.temAcesso === true
      );
    });
  }

  useEffect(() => {
    // const sorted = acessos.sort((a, b) => {
    //   return a.codigo.localeCompare(b.codigo, undefined, {
    //     numeric: true,
    //     sensitivity: "base",
    //   });
    // });
    construirNodes(acessos);
    if (selecionavel) {
      redefinirSelecaoNodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acessos]);

  return (
    <>
      {!selecionavel && (
        <CommonButton
          variant="outlined"
          onClick={() => abrirModalCriacaoTela("dominioAcesso", null)}
        >
          Criar novo domínio de acesso
        </CommonButton>
      )}

      {criacaoNode.open && (
        <CriacaoNodeAcessoControladoDialog
          open
          parentId={criacaoNode.parentId}
          tipo={criacaoNode.tipo}
          fechar={fecharModalCriacaoTela}
          criar={criarNodeTelaAcesso}
          verificarCodigoRepetido={verificarCodigoRepetido}
          reunirCodigos={reunirCodigos}
        />
      )}

      <TreeContainer>
        <CheckboxTree
          nodes={nodes}
          expanded={expanded}
          checked={checked}
          onCheck={alterarChecked}
          onExpand={expandir}
          showNodeIcon={false}
          noCascade={false}
          optimisticToggle
          showExpandAll
          checkModel="all"
          icons={{
            check: <Icon path={mdiCheckboxOutline} size={0.8} />,
            uncheck: <Icon path={mdiCheckboxBlankOutline} size={0.8} />,
            halfCheck: <Icon path={mdiMinusBoxOutline} size={0.8} />,
            expandClose: <Icon path={mdiChevronRight} size={0.8} />,
            expandOpen: <Icon path={mdiChevronDown} size={0.8} />,
            expandAll: <Icon path={mdiExpandAllOutline} size={0.8} />,
            collapseAll: <Icon path={mdiCollapseAllOutline} size={0.8} />,
            // parentClose: <Icon path={mdiClose} size={0.8} />,
            // parentOpen: <Icon path={mdiLaptop} size={0.8} />,
            leaf: <Icon path={mdiLeaf} size={0.8} />,
          }}
        />
      </TreeContainer>
    </>
  );
};
