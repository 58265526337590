import React, { PropsWithChildren } from "react";
import { OptDivider } from "@optsol/react";
import * as S from "./styles";

export const FooterToolbar = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div style={{ marginTop: 12, width: "100%" }}>
      <OptDivider marginy={20} />

      <S.Container>{children}</S.Container>
    </div>
  );
};
