import { OptFilterLink, OptSidebarFilterContainer } from "@optsol/react";
import { useEffect } from "react";
import { useGrupoAcessoContext } from "../../../contexts/grupoAcesso/grupoAcessoContext";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { Colors } from "../../../shared/colors";
import { RotaAtualProps } from "../../../shared/types/RotaAtualProps";

export const FiltroGrupoAcessoSidebar = ({ rotaAtual }: RotaAtualProps) => {
  const {
    state: { filtros },
    listarFiltrosAplicacao,
  } = useGrupoAcessoContext();

  useEffect(() => {
    listarFiltrosAplicacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptSidebarFilterContainer
      borderColor={rotaAtual ? "unset" : Colors.gray6}
      loading={filtros.loading}
    >
      {filtros.data.map((aplicacao) => (
        <OptFilterLink
          key={aplicacao.id}
          to={PainelPaths.GruposAcesso.Filtros(aplicacao.id).ListaGruposAcesso}
          name={aplicacao.nome}
          total={aplicacao.total}
        />
      ))}
    </OptSidebarFilterContainer>
  );
};
