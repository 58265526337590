import styled from "styled-components";

export const Container = styled.div`
  padding: 18px;
`;

export const Titulo = styled.div`
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 32px;
`;

// export const CardsContainer = styled.div`
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   flex-wrap: wrap;
// `;

// export const CardInformativo = styled.div`
//   margin-top: 40px;
//   height: 364px;
//   width: 364px;
//   padding: 20px;
//   box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
//   border-radius: 20px;
//   background-color: ${Colors.white};
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;

//   & img {
//     width: 88px;
//   }
// `;

// export const CardInformativoCorpo = styled.div`
//   & h4 {
//     color: ${Colors.gray2};
//     font-weight: bold;
//     font-size: 18px;
//     line-height: 21px;
//     margin-bottom: 40px;
//   }
// `;
