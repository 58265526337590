import { OptSelectionOption } from "@optsol/react";

export interface Usuario {
  id: string;
  nome: string;
  sobrenome: string;
  login: string;
  telefone: string;
  avatarSrc?: string;
  aplicacoes: UsuarioAplicacoes[];
}

export const USUARIO_RESPONSE_INITIAL_STATE: Usuario = {
  id: "",
  aplicacoes: [],
  login: "",
  nome: "",
  sobrenome: "",
  telefone: "",
  avatarSrc: "",
};

export interface UsuarioSearchResponseModel {
  id: string;
  titulo: string;
  nome: string;
  descricao: string;
  login: string;
  aplicacao: string;
  grupo: string;
}

export interface UsuarioSearchRequestModel {
  grupoAcessoId?: string;
  perfilAcessoId?: string;
}

export interface UsuarioAplicacoes {
  aplicacaoId: string;
  aplicacaoNome: string;
  habilitarBotaoAtivarUsuario: boolean;
  habilitarBotaoDesativarUsuario: boolean;
  habilitarBotaoReenviarLinkAtivacao: boolean;
  habilitarCancelarLinkAtivacao: boolean;
  gruposAcesso: OptSelectionOption[];
  perfisAcesso: OptSelectionOption[];
  usuarioAtivo: boolean;
  aplicacao?: OptSelectionOption;
}

export const USUARIO_APLICACAO_INITIAL_STATE: UsuarioAplicacoes = {
  aplicacaoNome: "",
  aplicacaoId: "",
  habilitarBotaoAtivarUsuario: false,
  habilitarBotaoDesativarUsuario: false,
  habilitarBotaoReenviarLinkAtivacao: false,
  habilitarCancelarLinkAtivacao: false,
  gruposAcesso: [],
  perfisAcesso: [],
  usuarioAtivo: false,
};
