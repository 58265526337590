import { Button } from "@mui/material";
import styled from "styled-components";

interface CommonButtonProps {
  bordercolor?: string;
  noTextTransform?: boolean;
  justifyStart?: boolean;
}

export const CommonButton = styled(Button)<CommonButtonProps>`
  padding: 16px 16px;
  border-radius: 6px;
  ${({ bordercolor }) => (bordercolor ? `border-color: ${bordercolor};` : "")}
  ${({ noTextTransform }) => (noTextTransform ? `text-transform: none;` : "")}

  &.MuiButtonBase-root {
    ${({ justifyStart }) =>
      justifyStart ? `justify-content: flex-start;` : ""}
  }
`;

CommonButton.defaultProps = {
  variant: "contained",
};
