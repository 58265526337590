import { Filtro } from '../../models/Filtro';
import { PerfilAcesso } from '../../models/PerfilAcesso';
import { LoadableData } from '../loadableData';

export interface PerfilAcessoState {
  filtros: LoadableData<Filtro[]>;
  lista: LoadableData<PerfilAcesso[]>;
}

export const PERFILACESSO_INITIAL_STATE: PerfilAcessoState = {
  filtros: { data: [], loading: false },
  lista: { data: [], loading: false },
};
