import React from "react";
import ReactDOM from "react-dom";
import "../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css";
import reportWebVitals from "./reportWebVitals";
import { AllRoutes } from "./routes/all.routes";

ReactDOM.render(
  // <React.StrictMode>
  <AllRoutes />,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
