import styled from "styled-components";
import { Colors } from "../../../colors";

export const TreeContainer = styled.div`
  .react-checkbox-tree {
    flex-direction: column;
  }

  .rct-node {
    padding: 4px 0;
  }
  .rct-node label,
  .rct-checkbox,
  .rct-collapse-btn {
    display: flex;
    align-items: center;
  }

  .rct-collapse * {
    width: 19.2px;
  }

  .rct-text,
  .rct-text > label,
  .rct-title,
  .rct-title > div,
  .rct-bare-label {
    flex: 1;
    display: flex;
  }

  .rct-bare-label:hover {
    background: ${Colors.gray6};
  }

  .rct-title {
    height: 32px;
    align-items: center;
  }
`;

export const TreeNodeEndContainer = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  margin-left: auto;
`;

export const TreeNode = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 12px;
  }
`;

export const TreeNodeDescription = styled.span`
  padding: 8px;
  flex: 1;
  display: flex;
  font-style: italic;
  font-size: 12px;
  color: ${Colors.gray4};
  justify-content: center;
  align-items: center;
`;
