import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { PainelPaths } from "../../routes/administracao/Painel.routes";
import { ProtectedContent } from "../../shared/components/ProtectedContent/ProtectedContent";
import * as S from "./styles";

export interface HomeLoading {}

export const Home = () => {
  const [teste, setTeste] = useState("");

  return (
    <S.Container>
      <S.Titulo>Home</S.Titulo>

      <NavLink to={PainelPaths.MeuPerfil}>Meu perfil</NavLink>

      <br />

      <ProtectedContent access="aplicacao.inserir">
        Deve exibir
      </ProtectedContent>
      <ProtectedContent access="aplicacao.xxx">
        Não deve exibir
      </ProtectedContent>

      <ProtectedContent access="aplicacao.xxx" mode="disable">
        <div>
          <input type="button" value="Teste" />
        </div>
      </ProtectedContent>
      <br />

      <ProtectedContent access="aplicacao.xxx" mode="disable">
        <Button
          onClick={() => console.error("não desabilitou")}
          variant="contained"
          color="primary"
        >
          DESABILITADO
        </Button>
      </ProtectedContent>

      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProtectedContent access="aplicacao.inserir" mode="disable">
            <TextField
              label="Campo habilitado"
              variant="outlined"
              value={teste}
              onChange={(e) => setTeste(e.target.value)}
            />

            <Button
              onClick={() => console.error("não desabilitou")}
              variant="contained"
              color="primary"
            >
              HABILITADO
            </Button>
          </ProtectedContent>
        </Grid>

        <Grid item xs={12}>
          <ProtectedContent access="aplicacao.xxx" mode="disable">
            <TextField
              label="Campo desabilitado"
              variant="outlined"
              value={teste}
              onChange={(e) => setTeste(e.target.value)}
            />

            <Button
              onClick={() => console.error("não desabilitou")}
              variant="contained"
              color="primary"
            >
              DESABILITADO
            </Button>
          </ProtectedContent>
        </Grid>

        <Grid item xs={12}>
          <ProtectedContent access="aplicacao.inserir" mode="disable">
            <p>Teste 2</p>
          </ProtectedContent>
        </Grid>
      </Grid>
    </S.Container>
  );
};
