export const Colors = {
  primary: "#2F80ED",
  primaryTints: {
    tint1: "#2f80ed",
    tint2: "#438cee",
    tint3: "#5899f0",
    tint4: "#6da6f2",
    tint5: "#82b2f4",
    tint6: "#97bff6",
    tint7: "#abccf7",
    tint8: "#c0d8f9",
    tint9: "#d5e5fb",
  },

  secondary: "#56CCF2",
  special: "#EAB735",
  white: "#FFFFFF",

  blue1: "#2F80ED",
  blue2: "#2D9CDB",
  blue3: "#56CCF2",
  blue4: "#17ada1",
  blue5: "#4e5cde",
  green1: "#219653",
  green2: "#27AE60",
  green3: "#6FCF97",
  orange: "#F2994A",
  red: "#EB5757",
  yellow: "#F2C94C",

  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray6: "#F2F2F2",

  black: "#000000",
} as const;
