import { Aplicacao } from '../../models/Aplicacao';
import { LoadableData } from '../loadableData';

export interface AplicacaoState {
  lista: LoadableData<Aplicacao[]>;
}

export const APLICACAO_INITIAL_STATE: AplicacaoState = {
  lista: { data: [], loading: false },
};
