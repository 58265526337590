import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Painel } from "../pages/app/Painel";
import { AtivarUsuarioView } from "../pages/confirmacao-ativacao-usuario/AtivarUsuarioView";
import { PainelPaths } from "./administracao/Painel.routes";

export const AllRoutesPaths = {
  Home: "/",
  AtivarUsuario: "/ativar",
} as const;

export const AllRoutes = () => (
  <SnackbarProvider
    maxSnack={4}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    autoHideDuration={4000}
  >
    <BrowserRouter>
      <Switch>
        <Route exact path={AllRoutesPaths.Home}>
          <Redirect to={PainelPaths.Home} />
        </Route>
        <Route path={PainelPaths.Home} component={Painel} />
        <Route
          path={AllRoutesPaths.AtivarUsuario}
          component={AtivarUsuarioView}
        />
      </Switch>
    </BrowserRouter>
  </SnackbarProvider>
);
