import * as Yup from "yup";
import { AplicacaoFormModel } from "../../../models/Aplicacao";

export const formAplicacaoSchema: Yup.SchemaOf<
  Omit<AplicacaoFormModel, "acessos">
> = Yup.object().shape({
  id: Yup.string().notRequired(),
  identificadorClient: Yup.string()
    .required("Identificador do Client obrigatório")
    .max(100, "Informe até 100 caracteres"),
  tipoAplicacaoId: Yup.string().notRequired(),
  url: Yup.string()
    .required("URL da aplicação obrigatória")
    .max(500, "Informe até 500 caracteres"),
  nome: Yup.string()
    .required("Nome obrigatório")
    .max(100, "Informe até 100 caracteres"),
  descricao: Yup.string()
    .required("Descrição obrigatória")
    .max(200, "Informe até 200 caracteres"),
  perfilAcessoPadraoUsuarioAutoCadastroId: Yup.string()
    .nullable()
    .notRequired(),
  // permiteUsuarioAutoCadastrar: Yup.boolean().notRequired(),
  // perfilAcessoPadraoUsuarioAutoCadastroId: Yup.string().nullable().required('Perfil de acesso obrigatório'),
  // .when('permiteUsuarioAutoCadastrar', {
  //   is: true,
  //   then: (rule) => rule.required('Perfil de acesso obrigatório'),
  // }),
});
