import { Aplicacao } from '../../models/Aplicacao';
import { GenericContext } from '../genericContext';
import { AplicacaoActions } from './aplicacaoActions';
import { AplicacaoState } from './aplicacaoState';

export type AplicacaoDispatch = (action: AplicacaoAction) => void;
export const APLICACAO_INITIAL_DISPATCH = (action: AplicacaoAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action);
};

export function AplicacaoReducer(state: AplicacaoState, action: AplicacaoAction): AplicacaoState {
  switch (action.type) {
    case AplicacaoActions.LOADING_LISTA: {
      return { ...state, lista: { ...state.lista, loading: !!action.payload } };
    }
    case AplicacaoActions.CARREGAR_LISTA: {
      return { ...state, lista: { data: action.payload || [], loading: false } };
    }
    default: {
      // eslint-disable-next-line
      throw new Error(`Ação não identificada: ${(action as any).type}`);
    }
  }
}

type AplicacaoAction =
  | GenericContext<AplicacaoActions.CARREGAR_LISTA, Aplicacao[]>
  | GenericContext<AplicacaoActions.LOADING_LISTA, boolean>;
