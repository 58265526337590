import React, { useEffect } from "react";
import { useAplicacaoContext } from "../../../contexts/aplicacao/aplicacaoContext";
import { ListaAplicacoesSidebarView } from "./ListaAplicacoesSidebarView";

interface Props {
  rotaAtual?: boolean;
}

export const ListaAplicacoesSidebar = ({ rotaAtual }: Props) => {
  const {
    state: { lista },
    listarAplicacoes,
  } = useAplicacaoContext();

  useEffect(() => {
    listarAplicacoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ListaAplicacoesSidebarView
      data={lista.data}
      loading={lista.loading}
      rotaAtual={rotaAtual}
    />
  );
};
