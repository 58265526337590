import { UserInfo } from '../../msal/UserInfo';
import { GenericContext } from '../genericContext';
import { AuthenticationActions } from './authenticationActions';
import { AuthenticationState } from './authenticationState';

export type AuthenticationDispatch = (action: AuthenticationAction) => void;
export const AUTHENTICATION_INITIAL_DISPATCH = (action: AuthenticationAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action);
};

export function AuthenticationReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case AuthenticationActions.LOADING_USER_INFO: {
      return { ...state, userInfo: { ...state.userInfo, loading: !!action.payload } };
    }
    case AuthenticationActions.CARREGAR_USER_INFO: {
      return { ...state, userInfo: { data: action.payload || undefined, loading: false } };
    }
    default: {
      // eslint-disable-next-line
      throw new Error(`Ação não identificada: ${(action as any).type}`);
    }
  }
}

type AuthenticationAction =
  | GenericContext<AuthenticationActions.CARREGAR_USER_INFO, UserInfo | undefined>
  | GenericContext<AuthenticationActions.LOADING_USER_INFO, boolean>;
