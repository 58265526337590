import { mdiDeleteOutline, mdiContentSave } from "@mdi/js";
import { Grid, TextField } from "@mui/material";
import {
  OptActionButton,
  OptActionToolbar,
  OptGridRequest,
  OptLoading,
  OptSelectionOption,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrorsImpl,
  UseFormHandleSubmit,
  UseFormReset,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { AcessoFormModel } from "../../../models/Acesso/Acesso";
import { GrupoAcessoFormModel } from "../../../models/GrupoAcesso";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { useGrupoAcessoService } from "../../../services/grupoAcesso.service";
import { useUsuarioService } from "../../../services/usuario.service";
import { Colors } from "../../../shared/colors";
import { ErrorMessage } from "../../../shared/components/ErrorMessage/ErrorMessage";
import {
  PaginatedSearchRequest,
  SearchRequest,
} from "../../../shared/types/SearchRequest";
import { AcessoControladoTree } from "../../aplicacoes/FormAplicacao/AcessoControladoTree/AcessoControladoTree";
import { FormGrupoAcessoLoading } from "./FormGrupoAcesso";
import { GridUsuario } from "./GridUsuario";
import * as S from "./styles";

interface Props {
  grupoAcesso: GrupoAcessoFormModel;
  estaEditando: boolean;
  salvar: (data: GrupoAcessoFormModel) => void;
  loading: FormGrupoAcessoLoading;
  remover: () => void;
  control: Control<GrupoAcessoFormModel>;
  handleSubmit: UseFormHandleSubmit<GrupoAcessoFormModel>;
  errors: Partial<FieldErrorsImpl<GrupoAcessoFormModel>>;
  reset: UseFormReset<GrupoAcessoFormModel>;
}

export const FormGrupoAcessoView = ({
  grupoAcesso,
  salvar,
  estaEditando,
  loading,
  remover,
  control,
  handleSubmit,
  errors,
  reset,
}: Props) => {
  const [loadingUsuario, setLoadingUsuario] = useState<boolean>();
  const [acessos, setAcessos] = useState<AcessoFormModel[]>([]);
  // const [isEdited, setIsEdited] = useState(false);

  const { buscarUsuariosGrupoAcesso, obterUsuarioAssociacao } =
    useUsuarioService();
  const { salvarUsuarioGrupoAcesso, removerUsuarioGrupoAcesso } =
    useGrupoAcessoService();
  const { enqueueSnackbar } = useSnackbar();

  const { grupoAcessoId, aplicacaoId } = useParams<{
    grupoAcessoId: string;
    aplicacaoId: string;
  }>();

  // const sinalizarEdicaoDeInput = () => {
  //   if (Object.keys(dirtyFields).length > 0) {
  //     setIsEdited(true);
  //   } else {
  //     setIsEdited(false);
  //   }
  // };

  // useEffect(() => {
  //   if (isEdited) {
  //     document.getElementById("submit-editar-aplicacao")?.click();
  //     setIsEdited(false);
  //   }
  // }, [isEdited]);

  // useEffect(() => {
  //   return () => {
  //     if (acessos) document.getElementById("submit-editar-aplicacao")?.click();
  //   };
  // }, [acessos]);

  function salvarHandler(data: GrupoAcessoFormModel) {
    data.acessos = acessos ?? [];
    data.aplicacaoId = aplicacaoId;
    salvar(data);
  }

  function redefinirAcessos(acessosRedefinidos: AcessoFormModel[]) {
    acessosRedefinidos.forEach((element) => {
      if (element.acessosFilhos && element.acessosFilhos?.length > 0) {
        element.temAcesso = element.acessosFilhos.some(
          (x) => x.temAcesso === true
        );
      }
    });
    setAcessos(acessosRedefinidos);
  }

  async function obterUsuariosAssociados(termo: string) {
    const response = await obterUsuarioAssociacao(
      aplicacaoId,
      grupoAcessoId,
      termo
    );
    const data: OptSelectionOption[] = response.map((x) => ({
      value: x.id!,
      label: x.login,
    }));
    return data;
  }

  async function adicionarUsuarios(data: string[]) {
    try {
      setLoadingUsuario(true);
      await salvarUsuarioGrupoAcesso(grupoAcessoId, data);
      enqueueSnackbar(`Registro salvo com sucesso!`, { variant: "success" });
      setLoadingUsuario(false);
    } catch (error: any) {
      enqueueSnackbar(error.messages[0], { variant: "error" });
      setLoadingUsuario(false);
    }
  }

  async function removerUsuario(id: string) {
    try {
      setLoadingUsuario(true);
      await removerUsuarioGrupoAcesso(grupoAcessoId, id);
      enqueueSnackbar(`Registro removido com sucesso!`, { variant: "success" });
      setLoadingUsuario(false);
    } catch (error: any) {
      enqueueSnackbar(error.messages[0], { variant: "error" });
      setLoadingUsuario(false);
    }
  }

  const carregarDadosGrid = (
    query: OptGridRequest,
    UsuariosSearchRequest: PaginatedSearchRequest = {
      grupoAcessoId: grupoAcessoId,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: UsuariosSearchRequest,
    };
    return buscarUsuariosGrupoAcesso(request);
  };

  useEffect(() => {
    reset(grupoAcesso);
    setAcessos(grupoAcesso.acessos);
  }, [grupoAcesso, reset]);

  return (
    <>
      <OptActionToolbar
        title={`${estaEditando ? "Editar" : "Criar"} grupo de acesso`}
        goBackRoute={
          PainelPaths.GruposAcesso.Filtros(grupoAcessoId).ListaGruposAcesso
        }
      >
        <S.ActionsContainer>
          {estaEditando && (
            <OptActionButton
              onClick={remover}
              startIcon={{ path: mdiDeleteOutline, color: Colors.red }}
              loading={loading.excluir}
            >
              Excluir
            </OptActionButton>
          )}
          <OptActionButton
            onClick={handleSubmit(salvarHandler)}
            startIcon={{ path: mdiContentSave, color: Colors.blue1 }}
            loading={loading.salvar}
          >
            {estaEditando ? "Salvar" : "Criar registro"}
          </OptActionButton>
        </S.ActionsContainer>
      </OptActionToolbar>
      <form onSubmit={handleSubmit(salvarHandler)}>
        <input
          type="submit"
          id="submit-editar-aplicacao"
          style={{ display: "none" }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <S.SecaoForm>
              <S.TituloSecao>Grupo de acesso</S.TituloSecao>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Nome"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.nome} />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="codigo"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        disabled={estaEditando}
                        label="Código"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        inputProps={{
                          maxLength: 5,
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.codigo} />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricao"
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        label="Descrição"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.descricao} />
                  <ErrorMessage error={errors.tenantId} />
                </Grid>
              </Grid>
            </S.SecaoForm>

            {estaEditando && (
              <Grid item xs={12}>
                <S.SecaoFormControlados>
                  <S.TituloSecao>Acessos controlados</S.TituloSecao>

                  <AcessoControladoTree
                    acessos={acessos}
                    selecionavel
                    alterarAcessos={redefinirAcessos}
                  />
                </S.SecaoFormControlados>
              </Grid>
            )}
          </Grid>

          {estaEditando && (
            <Grid item xs={12} lg={6}>
              <S.SecaoFormGridUsuario>
                <GridUsuario
                  remover={removerUsuario}
                  adicionar={adicionarUsuarios}
                  carregarGrid={carregarDadosGrid}
                  carregarOptionsUsuario={obterUsuariosAssociados}
                />
              </S.SecaoFormGridUsuario>
            </Grid>
          )}
          {loadingUsuario && <OptLoading size={50} />}
        </Grid>
      </form>
    </>
  );
};
