import { yupResolver } from "@hookform/resolvers/yup";
import { mdiDeleteOutline } from "@mdi/js";
import {
  OptBackdrop,
  OptConfirmationDialog,
  OptLoading,
  OptSideLayoutContent,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useGrupoAcessoContext } from "../../../contexts/grupoAcesso/grupoAcessoContext";
import { GrupoAcessoFormModel } from "../../../models/GrupoAcesso";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { useGrupoAcessoService } from "../../../services/grupoAcesso.service";
import { Colors } from "../../../shared/colors";
import { formGrupoAcessoSchema } from "./FormGrupoAcesso.validation";
import { FormGrupoAcessoView } from "./FormGrupoAcessoView";

export interface FormGrupoAcessoLoading {
  carregar: boolean;
  excluir: boolean;
  salvar: boolean;
}

const INITIAL_GRUPOACESSO_STATE: GrupoAcessoFormModel = {
  nome: "",
  descricao: "",
  aplicacaoId: "",
  codigo: "",
  acessos: [],
  tenantId: "00000000-0000-0000-0000-000000000000",
};

export const FormGrupoAcesso = () => {
  const [confirmandoExclusao, setConfirmandoExclusao] = useState(false);
  const [grupoAcesso, setGrupoAcesso] = useState<GrupoAcessoFormModel>(
    INITIAL_GRUPOACESSO_STATE
  );
  const [loading, setLoading] = useState<FormGrupoAcessoLoading>({
    carregar: false,
    excluir: false,
    salvar: false,
  });

  const { grupoAcessoId } = useParams<{
    grupoAcessoId: string;
  }>();

  const {
    state: { tenantId },
  } = useGrupoAcessoContext();

  const {
    listarGruposAcessoPorAplicacao,
    incrementarFiltroAplicacao,
    decrementarFiltroAplicacao,
  } = useGrupoAcessoContext();
  const { obterGrupoAcesso, salvarGrupoAcesso, excluirGrupoAcesso } =
    useGrupoAcessoService();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const estaEditando = !!grupoAcessoId;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm<GrupoAcessoFormModel>({
    defaultValues: grupoAcesso,
    resolver: yupResolver(formGrupoAcessoSchema),
  });

  async function carregar() {
    setLoading({ ...loading, carregar: true });

    let data = INITIAL_GRUPOACESSO_STATE;

    if (grupoAcessoId) {
      data = await obterGrupoAcesso(grupoAcessoId);
    }

    setGrupoAcesso(data);
    setLoading({ ...loading, carregar: false });
  }

  async function salvar(data: GrupoAcessoFormModel) {
    try {
      setLoading({ ...loading, salvar: true });

      data.id = grupoAcesso.id;
      const result = await salvarGrupoAcesso(data);

      const operacao = estaEditando ? "editado" : "criado";
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });

      setGrupoAcesso(data);

      setLoading({ ...loading, salvar: false });
      listarGruposAcessoPorAplicacao(
        data.aplicacaoId!,
        // "00000000-0000-0000-0000-000000000000"
        grupoAcesso.tenantId
      );

      if (!estaEditando) {
        incrementarFiltroAplicacao(data.aplicacaoId!);
        history.push(
          PainelPaths.GruposAcesso.Filtros(data.aplicacaoId!).EditarGrupoAcesso(
            result.id
          )
        );
      }
    } catch (error: any) {
      enqueueSnackbar("Erro ao salvar registro.", { variant: "error" });
      setLoading({ ...loading, salvar: false });
    }
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  async function excluir() {
    try {
      setConfirmandoExclusao(false);
      setLoading({ ...loading, excluir: true });

      await excluirGrupoAcesso(grupoAcessoId);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });

      decrementarFiltroAplicacao(grupoAcesso.aplicacaoId!);
      // TODO remover sem dar refresh?
      listarGruposAcessoPorAplicacao(
        grupoAcesso.aplicacaoId!,
        // "00000000-0000-0000-0000-000000000000"
        grupoAcesso.tenantId
      );

      setLoading({ ...loading, excluir: false });
      history.push(
        PainelPaths.GruposAcesso.Filtros(grupoAcesso.aplicacaoId!)
          .ListaGruposAcesso
      );
    } catch (error: any) {
      setLoading({ ...loading, excluir: false });
      // enqueueSnackbar(error.message, { variant: "error" });
      enqueueSnackbar("Erro ao excluir registro.", { variant: "error" });
    }
  }

  useEffect(() => {
    carregar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grupoAcessoId]);

  useEffect(() => {
    setValue("tenantId", tenantId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  return (
    <OptSideLayoutContent>
      <OptBackdrop open={loading.excluir || loading.salvar} />

      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir grupo de acesso"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={excluir}
      >
        Deseja confirmar a exclusão do grupo de acesso?
      </OptConfirmationDialog>

      {loading.carregar && <OptLoading size={50} />}

      {!loading.carregar && (
        <FormGrupoAcessoView
          grupoAcesso={grupoAcesso}
          salvar={salvar}
          estaEditando={estaEditando}
          loading={loading}
          remover={excluir}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          reset={reset}
        />
      )}
    </OptSideLayoutContent>
  );
};
