import { mdiHome, mdiLaptop, mdiLock } from "@mdi/js";
import { OptMenuSection } from "@optsol/react";
import { PainelPaths } from "../../routes/administracao/Painel.routes";

export const sections: OptMenuSection[] = [
  {
    items: [
      {
        icon: mdiHome,
        path: PainelPaths.Home,
        title: "Home",
        activeShouldBeExact: true,
      },
      {
        icon: mdiLaptop,
        path: PainelPaths.Aplicacoes.Principal,
        title: "Aplicações",
      },
      {
        icon: mdiLock,
        path: PainelPaths.GruposAcesso.Principal,
        title: "Grupo de acesso",
      },
    ],
  },
];
