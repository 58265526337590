/* eslint-disable @typescript-eslint/no-explicit-any */
import { OptGridResponse, OptSearchResponse } from "@optsol/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useSnackbar } from "notistack";
import { useMsalService } from "../services/authentication/msal.service";
import { useErrorSnackbar } from "../shared/hooks/errorSnackbar";
import { IApiError } from "../shared/types/ApiError";
import { ApiResponse } from "../shared/types/ApiResponse";
import { SearchRequest } from "../shared/types/SearchRequest";
import { BaseConfig } from "./baseConfig";

export type UseApiOptions = {
  semToken?: boolean;
  external?: boolean;
};

export function useApi({ semToken, external }: Partial<UseApiOptions> = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const { tratarErro } = useErrorSnackbar();
  const { getAccessToken } = useMsalService();

  const api = axios.create({
    baseURL: !external ? BaseConfig.baseApiUrl : "",
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  api.interceptors.request.use(
    async (config) => {
      const configValida = config !== undefined && config.url !== undefined;

      if (configValida) {
        try {
          const deveObterToken = !semToken;

          if (deveObterToken) {
            const token = await getAccessToken();

            if (token && config.headers !== undefined) {
              config.headers.Authorization = `Bearer ${token}`;
              // se for necessário verificar expiração do token, considere o fato dele estar em UNIX DATE
              // const tokenValido = token !== undefined && token.accessToken !== undefined;
              // if (tokenValido) {
              //   config.headers['Authorization'] = `Bearer ${token.accessToken}`;
              // }
            }
          }
        } catch (e) {
          console.error(e);
        }
      }

      return config;
    },
    (error) => {
      // TODO: exibir alerta?
      if (error.response.status === 403) {
        enqueueSnackbar(error + ": Forbidden", { variant: "error" });
      }

      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (axiosResponse) => {
      const responseInvalida = !axiosResponse.data;

      if (responseInvalida) {
        console.info("Controller não retornou IActionResult!");
      }

      return axiosResponse.data;
    },
    (error: AxiosError<IApiError>) => {
      tratarErro(error.response?.data);
      return Promise.reject(error.response?.data);
    }
  );

  function gridSearch<T extends object>(
    url: string,
    data: SearchRequest<any>,
    config?: AxiosRequestConfig
  ) {
    data.page += 1;

    return api
      .post<T, OptSearchResponse<T>>(url, data, config)
      .then((response) => {
        const r: OptGridResponse<T> = {
          data: response.data,
          page: response.page - 1,
          totalCount: response.total,
        };

        return r;
      });
  }

  function search<T, R = OptSearchResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, R>(url, data, config);
  }

  function post<T, R = ApiResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, R>(url, data, config);
  }

  function put<T, R = ApiResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.put<T, R>(url, data, config);
  }

  function get<T, R = ApiResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ) {
    return api.get<T, R>(url, config);
  }

  function getRoot<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  function remove<T, R = ApiResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ) {
    return api.delete<T, R>(url, config);
  }

  return { search, gridSearch, post, put, get, remove, getRoot };
}
