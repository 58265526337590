import React from "react";
import { FieldError } from "react-hook-form";

interface Props {
  error?: FieldError | (FieldError | undefined)[];
}

export const ErrorMessage = ({ error }: Props) => {
  if (!error) {
    return null;
  } else if (Array.isArray(error)) {
    return (
      <>
        {error.map((x, index) => (
          <ErrorMessage error={x} key={index} />
        ))}
      </>
    );
  } else {
    return <p style={{ color: "red", marginTop: "4px" }}>{error.message}</p>;
  }
};
