import { OptSelectionOption } from "@optsol/react";
import {
  Aplicacao,
  AplicacaoFormModel,
  AplicacaoResponseModel,
  SecretStringKey,
} from "../models/Aplicacao";
import { TenantsGrupoAcessoModel } from "../models/GrupoAcesso";
import { InsertResponse } from "../shared/types/InsertResponse";
import { useApi } from "../utils/api";

export function useAplicacaoService() {
  const api = useApi({ semToken: false });
  const baseApi = "/api/aplicacao";

  async function listarAplicacoes(): Promise<Aplicacao[]> {
    const result = await api.get<Aplicacao[]>(`${baseApi}`);
    return result.data;
  }

  async function obterAplicacao(id: string) {
    const result = await api.get<AplicacaoResponseModel>(`${baseApi}/${id}`);
    return result.data;
  }

  async function gerarSecret(id: string) {
    const result = await api.get<SecretStringKey>(
      `${baseApi}/${id}/gerar-secret`
    );
    return result.data;
  }

  async function obterTenantAplicacaoAcesso(id: string) {
    const result = await api.get<TenantsGrupoAcessoModel[]>(
      `${baseApi}/${id}/tenants`
    );
    return result.data;
  }

  /////////////////////////////////////////////////////////////////////////////////

  async function salvarAplicacao(data: AplicacaoFormModel) {
    // todo: não deixar 2 fluxos (2 returns)
    if (data.id) {
      const result = await api.put<InsertResponse>(
        `${baseApi}/${data.id}`,
        data
      );
      return result.data;
    } else {
      const result = await api.post<InsertResponse>(`${baseApi}`, data);
      return result.data;
    }
  }

  async function excluirAplicacao(id: string) {
    const result = await api.remove(`${baseApi}/${id}`);
    return result.data;
  }

  // ?????
  async function listarGruposParaSelecao(
    aplicacaoId: string
  ): Promise<OptSelectionOption[]> {
    const result = await api.get<OptSelectionOption[]>(
      `/api/aplicacao/${aplicacaoId}/grupos-acesso`
    );
    return result.data;
  }

  return {
    listarAplicacoes,
    obterAplicacao,
    gerarSecret,
    excluirAplicacao,
    salvarAplicacao,
    listarGruposParaSelecao,
    obterTenantAplicacaoAcesso,
  };
}
