import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { FormAplicacao } from "../../pages/aplicacoes/FormAplicacao/FormAplicacao";
import { ListaAplicacoesSidebar } from "../../pages/aplicacoes/ListaAplicacoesSidebar/ListaAplicacoesSidebar";
import { PainelPaths } from "./Painel.routes";

export const AplicacoesListaRoutes = () => {
  const match = useRouteMatch({
    path: [PainelPaths.Aplicacoes.Principal],
    exact: true,
  });

  return (
    <OptSideLayoutPortalContainer>
      <ListaAplicacoesSidebar rotaAtual={!!match?.isExact} />

      <OptSideLayoutPortalContent>
        <Switch>
          <Route
            exact
            path={PainelPaths.Aplicacoes.CriarAplicacao}
            component={FormAplicacao}
          />
          <Route
            exact
            path={PainelPaths.Aplicacoes.EditarAplicacao()}
            component={FormAplicacao}
          />
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
