import React from 'react';
import App from './App';
import { AppProviders } from './App.providers';

export const Painel = () => {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  );
};
