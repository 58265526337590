import React, { createContext, useReducer } from 'react';
import { usePerfilAcessoService } from '../../services/perfilAcesso.service';
import { deepCopy } from '../../utils/functions';
import { PerfilAcessoActions } from './perfilAcessoActions';
import { PerfilAcessoDispatch, PerfilAcessoReducer, PERFILACESSO_INITIAL_DISPATCH } from './perfilAcessoReducer';
import { PerfilAcessoState, PERFILACESSO_INITIAL_STATE } from './perfilAcessoState';

const PerfilAcessoStateContext = createContext<PerfilAcessoState>(PERFILACESSO_INITIAL_STATE);
const PerfilAcessoDispatchContext = createContext<PerfilAcessoDispatch>(PERFILACESSO_INITIAL_DISPATCH);

type PerfilAcessoProps = { children: React.ReactNode };

function PerfilAcessoProvider({ children }: PerfilAcessoProps) {
  const [state, dispatch] = useReducer(PerfilAcessoReducer, PERFILACESSO_INITIAL_STATE);

  return (
    <PerfilAcessoStateContext.Provider value={state}>
      <PerfilAcessoDispatchContext.Provider value={dispatch}>{children}</PerfilAcessoDispatchContext.Provider>
    </PerfilAcessoStateContext.Provider>
  );
}

function usePerfilAcessoContext() {
  const state = React.useContext(PerfilAcessoStateContext);
  const perfilAcessoService = usePerfilAcessoService();

  if (state === undefined) {
    throw new Error('usePerfilAcessoState deve ser utilizando dentro de um PerfilAcessoProvider');
  }

  const dispatch = React.useContext(PerfilAcessoDispatchContext);

  if (dispatch === undefined) {
    throw new Error('usePerfilAcessoDispatch deve ser utilizando dentro de um PerfilAcessoProvider');
  }

  const actions = PerfilAcessoActions;

  async function decrementarFiltroAplicacao(aplicacaoId: string) {
    const novosFiltros = deepCopy(state.filtros);

    const filtroEncontrado = novosFiltros.data.find((x) => x.id === aplicacaoId);

    if (filtroEncontrado) {
      filtroEncontrado.total -= 1;
      dispatch({ type: actions.CARREGAR_FILTROS, payload: novosFiltros.data });
    }
  }

  async function incrementarFiltroAplicacao(aplicacaoId: string) {
    const novosFiltros = deepCopy(state.filtros);

    const filtroEncontrado = novosFiltros.data.find((x) => x.id === aplicacaoId);

    if (filtroEncontrado) {
      filtroEncontrado.total += 1;
      dispatch({ type: actions.CARREGAR_FILTROS, payload: novosFiltros.data });
    }
  }

  async function listarFiltrosAplicacao() {
    try {
      dispatch({ type: actions.LOADING_FILTROS, payload: true });

      const result = await perfilAcessoService.listarFiltrosAplicacao();

      dispatch({ type: actions.CARREGAR_FILTROS, payload: result });
    } catch (error) {
      dispatch({ type: actions.LOADING_FILTROS, payload: false });
      throw error;
    }
  }

  async function listarPerfilAcessoPorAplicacao(aplicacaoId: string) {
    try {
      dispatch({ type: actions.LOADING_LISTA, payload: true });

      const result = await perfilAcessoService.listarPerfilAcessoPorAplicacao(aplicacaoId);

      dispatch({ type: actions.CARREGAR_LISTA, payload: result });
    } catch (error) {
      dispatch({ type: actions.LOADING_LISTA, payload: false });
      throw error;
    }
  }

  return {
    state,
    listarFiltrosAplicacao,
    incrementarFiltroAplicacao,
    decrementarFiltroAplicacao,
    listarPerfilAcessoPorAplicacao,
  };
}

export { PerfilAcessoProvider, usePerfilAcessoContext };
