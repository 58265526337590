import React, { createContext, useReducer } from 'react';
import { deepCopy } from '../../utils/functions';
import { UsuarioActions } from './usuarioActions';
import { UsuarioDispatch, UsuarioReducer, USUARIO_INITIAL_DISPATCH } from './usuarioReducer';
import { UsuarioState, USUARIO_INITIAL_STATE } from './usuarioState';

const UsuarioStateContext = createContext<UsuarioState>(USUARIO_INITIAL_STATE);
const UsuarioDispatchContext = createContext<UsuarioDispatch>(USUARIO_INITIAL_DISPATCH);

type UsuarioProps = { children: React.ReactNode };

function UsuarioProvider({ children }: UsuarioProps) {
  const [state, dispatch] = useReducer(UsuarioReducer, USUARIO_INITIAL_STATE);
  return (
    <UsuarioStateContext.Provider value={state}>
      <UsuarioDispatchContext.Provider value={dispatch}>{children}</UsuarioDispatchContext.Provider>
    </UsuarioStateContext.Provider>
  );
}

function useUsuarioContext() {
  const state = React.useContext(UsuarioStateContext);

  if (state === undefined) {
    throw new Error('useUsuarioState deve ser utilizando dentro de um UsuarioProvider');
  }

  const dispatch = React.useContext(UsuarioDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useUsuarioDispatch deve ser utilizando dentro de um UsuarioProvider');
  }

  const actions = UsuarioActions;

  async function incrementarUsuario() {
    const contadorValue = deepCopy(state.contador);

    contadorValue.data += 1;
    dispatch({ type: actions.ATUALIZAR_CONTADOR, payload: contadorValue.data });
  }

  return {
    state,
    incrementarUsuario,
  };
}

export { UsuarioProvider, useUsuarioContext };
