import { AtivacaoUsuarioEmpresa } from '../models/AtivacaoUsuarioEmpresa';
import { Filtro } from '../models/Filtro';
import { PerfilAcessoSearchResponseModel } from '../models/PerfilAcesso';
import { Usuario, USUARIO_RESPONSE_INITIAL_STATE } from '../models/Usuario';
import { UsuarioFormModel } from '../models/UsuarioFormModel';
import { InsertResponse } from '../shared/types/InsertResponse';
import { SearchRequest } from '../shared/types/SearchRequest';
import { useApi } from '../utils/api';

export function useUsuarioService() {
  const api = useApi();
  const { gridSearch } = useApi();
  const baseApi = 'api/usuario';

  async function buscar(searcRequest: SearchRequest<{}>) {
    const result = await api.gridSearch<Usuario>(`${baseApi}/paginated`, searcRequest);

    return result;
  }

  async function salvar(data: UsuarioFormModel) {
    if (data.id) {
      const response = await api.put<InsertResponse>(`api/usuario/${data.id}`, data);
      return response.data;
    } else {
      const response = await api.post<InsertResponse>('api/usuario', data);
      return response.data;
    }
  }

  async function obter(usuarioId: string) {
    if (usuarioId) {
      const result = await api.get<Usuario>(`${baseApi}/${usuarioId}`);
      return result.data;
    }
    return USUARIO_RESPONSE_INITIAL_STATE;
  }

  async function listarFiltrosAplicacao(): Promise<Filtro[]> {
    const result = await api.get<Filtro[]>('/api/usuario/filtro-tipo-aplicacao');

    return result.data;
  }

  async function buscarUsuariosGrupoAcesso(data: SearchRequest<{}>) {
    return gridSearch<PerfilAcessoSearchResponseModel>(baseApi + '/grupo-acesso/usuarios/', data);
  }

  async function obterUsuarioAssociacao(aplicacaoId: string, grupoAcessoId: string, termo: string) {
    const result = await api.get<UsuarioFormModel[]>(
      `${baseApi}/aplicacao/${aplicacaoId}/grupo-acesso/${grupoAcessoId}/usuarios-associar?termo=${termo}`,
    );

    return result.data;
  }

  async function ativarUsuario(code: string) {
    const baseApiAplicacao = 'api/usuario';
    const result = await api.get<AtivacaoUsuarioEmpresa>(`${baseApiAplicacao}/ativar?code=${code}`);

    return result.data;
  }

  async function obterUsuarioPerfilAcessoAssociacao(aplicacaoId: string, perfilAcessoId: string, termo: string) {
    const result = await api.get<UsuarioFormModel[]>(
      `${baseApi}/aplicacao/${aplicacaoId}/perfil-acesso/${perfilAcessoId}/usuarios-associar?termo=${termo}`,
    );

    return result.data;
  }

  async function reenviarLink(code: string) {
    const baseApiAplicacao = 'api/usuario';
    const result = await api.get<void>(`${baseApiAplicacao}/reenviar-link-ativacao?code=${code}`);

    return result.data;
  }

  async function buscarUsuariosPerfilAcesso(data: SearchRequest<{}>) {
    return gridSearch<PerfilAcessoSearchResponseModel>(baseApi + '/perfil-acesso/usuarios/', data);
  }

  async function listarAplicacoesAssociar(usuarioId: string): Promise<Filtro[]> {
    const result = await api.get<Filtro[]>(`/api/usuario/${usuarioId}/aplicacoes-associar`);
    return result.data;
  }

  async function desativarUsuario(usuarioId: string, aplicacaoId: string) {
    return api.put(baseApi + `/${usuarioId}/aplicacao/${aplicacaoId}/desativar`);
  }

  async function reenviarLinkAtivacao(usuarioId: string, aplicacaoId: string) {
    return api.put(baseApi + `/${usuarioId}/aplicacao/${aplicacaoId}/reenviar-link-ativacao`);
  }

  async function cancelarAtivacao(usuarioId: string, aplicacaoId: string) {
    return api.put(baseApi + `/${usuarioId}/aplicacao/${aplicacaoId}/cancelar-ativacao-pendente`);
  }

  return {
    buscar,
    salvar,
    obter,
    listarFiltrosAplicacao,
    buscarUsuariosGrupoAcesso,
    obterUsuarioAssociacao,
    ativarUsuario,
    reenviarLink,
    obterUsuarioPerfilAcessoAssociacao,
    listarAplicacoesAssociar,
    desativarUsuario,
    reenviarLinkAtivacao,
    cancelarAtivacao,
    buscarUsuariosPerfilAcesso,
  };
}
