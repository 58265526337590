import { yupResolver } from "@hookform/resolvers/yup";
import {
  mdiContentSave,
  mdiDeleteOutline,
  mdiShieldKeyOutline,
  mdiTextBoxCheckOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  OptActionButton,
  OptActionToolbar,
  OptConfirmationDialog,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AcessoFormModel } from "../../../models/Acesso/Acesso";
import { AplicacaoFormModel, SecretStringKey } from "../../../models/Aplicacao";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { Colors } from "../../../shared/colors";
import { CommonButton } from "../../../shared/components/Buttons/CommonButton";
import { ErrorMessage } from "../../../shared/components/ErrorMessage/ErrorMessage";
import { gerarClaimsModel, gerarEnumModel } from "../../../utils/functions";
import { AcessoControladoTree } from "./AcessoControladoTree/AcessoControladoTree";
import { FormAplicacaoLoading } from "./FormAplicacao";
import { formAplicacaoSchema } from "./FormAplicacao.validation";
import * as S from "./styles";

interface Props {
  data: AplicacaoFormModel;
  estaEditando: boolean;
  salvar: (data: AplicacaoFormModel) => void;
  remover: () => void;
  loading: FormAplicacaoLoading;
  secretString?: SecretStringKey;
  obterSecret: () => Promise<void>;
}

export const FormAplicacaoView = ({
  data,
  salvar,
  estaEditando,
  remover,
  loading,
  secretString,
  obterSecret,
}: Props) => {
  const [acessos, setAcessos] = useState<AcessoFormModel[]>([]);
  const [openConfirmacao, setOpenConfirmacao] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AplicacaoFormModel>({
    defaultValues: data,
    resolver: yupResolver(formAplicacaoSchema),
  });

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function salvarHandler(data: AplicacaoFormModel) {
    data.acessos = acessos;
    salvar(data);
  }

  function atualizarAcessos(novosAcessos: AcessoFormModel[]) {
    setAcessos(novosAcessos);
  }

  function abrirConfirmacao() {
    setOpenConfirmacao(true);
  }

  function fecharConfirmacao() {
    setOpenConfirmacao(false);
  }

  function gerarClaims(back?: boolean) {
    const claims = !back
      ? gerarClaimsModel(data.acessos)
      : gerarClaimsModel(data.acessos, true);

    navigator.clipboard.writeText(claims.replace(/^"(.+(?="$))"$/, "$1"));
    enqueueSnackbar(`O modelo de Claims foi copiado para o seu clipboard.`, {
      variant: "info",
    });
  }

  function gerarEnum() {
    const claims = gerarEnumModel(data.acessos);

    navigator.clipboard.writeText(claims.replace(/^"(.+(?="$))"$/, "$1"));
    enqueueSnackbar(`O modelo de Enum foi copiado para o seu clipboard.`, {
      variant: "info",
    });
  }

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  useEffect(() => {
    setAcessos(data.acessos);
    // setAcessosClaims(gerarClaimsModel(data.acessos));
  }, [data]);

  return (
    <>
      <OptActionToolbar
        title={`${estaEditando ? "Editar" : "Criar"} aplicação`}
        goBackRoute={PainelPaths.Aplicacoes.Principal}
      >
        <S.ActionsContainer>
          {estaEditando && (
            <OptActionButton
              onClick={remover}
              startIcon={{ path: mdiDeleteOutline, color: Colors.red }}
              loading={loading.excluir}
            >
              Excluir
            </OptActionButton>
          )}

          <OptActionButton
            onClick={handleClickOpen}
            startIcon={{ path: mdiTextBoxCheckOutline, color: Colors.blue1 }}
          >
            Claims/Enum
          </OptActionButton>

          <Menu
            id="claims-menus"
            aria-labelledby="claims-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                gerarClaims();
                handleClose();
              }}
            >
              <ListItemIcon>
                <Icon
                  path={mdiTextBoxCheckOutline}
                  color={Colors.blue4}
                  size={1}
                />
              </ListItemIcon>
              Claims Front
            </MenuItem>
            <MenuItem
              onClick={() => {
                gerarClaims(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <Icon
                  path={mdiTextBoxCheckOutline}
                  color={Colors.blue5}
                  size={1}
                />
              </ListItemIcon>
              Claims Back
            </MenuItem>
            <MenuItem
              onClick={() => {
                gerarEnum();
                handleClose();
              }}
            >
              <ListItemIcon>
                <Icon
                  path={mdiTextBoxCheckOutline}
                  color={Colors.gray3}
                  size={1}
                />
              </ListItemIcon>
              Enum Back
            </MenuItem>
          </Menu>
          <OptActionButton
            onClick={handleSubmit(salvarHandler)}
            startIcon={{ path: mdiContentSave, color: Colors.blue1 }}
            loading={loading.salvar}
          >
            {estaEditando ? "Salvar" : "Criar registro"}
          </OptActionButton>
        </S.ActionsContainer>
      </OptActionToolbar>
      <form onSubmit={handleSubmit(salvarHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <S.SecaoForm>
              <S.TituloSecao>Aplicação</S.TituloSecao>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="nome"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Nome"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.nome} />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="descricao"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        label="Descrição"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.descricao} />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="identificadorClient"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Identificador de Client"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.identificadorClient} />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="url"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="URL da aplicação"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <ErrorMessage error={errors.url} />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" gap={2}>
                    <CommonButton
                      onClick={abrirConfirmacao}
                      style={{
                        height: "55px",
                        width: "90px",
                        textTransform: "capitalize",
                      }}
                    >
                      Gerar Secret
                    </CommonButton>
                    <TextField
                      variant="outlined"
                      label="Secret"
                      fullWidth
                      disabled
                      value={secretString?.value ?? ""}
                    />
                  </Box>
                </Grid>
              </Grid>
            </S.SecaoForm>
          </Grid>
          <Grid item xs={12} md={6}>
            <S.SecaoForm>
              <S.TituloSecao>Acessos controlados</S.TituloSecao>
              <AcessoControladoTree
                acessos={acessos}
                alterarAcessos={atualizarAcessos}
              />
            </S.SecaoForm>
          </Grid>
        </Grid>
      </form>
      <OptConfirmationDialog
        open={openConfirmacao}
        title="Gerar Secret"
        icon={{ path: mdiShieldKeyOutline, color: Colors.blue1 }}
        onCancel={fecharConfirmacao}
        onClose={fecharConfirmacao}
        onConfirm={obterSecret}
      >
        Deseja confirmar a geração de um novo Secret?
      </OptConfirmationDialog>
    </>
  );
};
