import { createGlobalStyle } from "styled-components";
import { Colors } from "../colors";

export const GlobalStyles = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
  }
  body {
      font-family: --apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; // Reseta para fonts padrões dos dispositivos
      background-color: ${Colors.white};
      color: ${Colors.gray2};
  }

  html, body, #root {
      height: 100%;
  }

  #root {
    display: flex;
    overflow-y: auto;
  }
`;
