import { Box } from "@mui/material";
import { OptSelectionOption, OptSidebarListContainer } from "@optsol/react";
import AsyncSelect from "react-select/async";
import { GrupoAcesso } from "../../../models/GrupoAcesso";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { Colors } from "../../../shared/colors";
import { RotaAtualProps } from "../../../shared/types/RotaAtualProps";

interface Props extends RotaAtualProps {
  gruposAcesso: GrupoAcesso[];
  loading: boolean;
  aplicacaoId: string;
  obterTenants(): Promise<OptSelectionOption[]>;
  selecionarTenantGrupoAcesso(tenantId: string): void;
}

export const ListaGruposAcessoSidebarView = ({
  gruposAcesso,
  loading,
  rotaAtual,
  aplicacaoId,
  obterTenants,
  selecionarTenantGrupoAcesso,
}: Props) => {
  return (
    <OptSidebarListContainer
      goBackRoute={PainelPaths.GruposAcesso.Filtros().Principal}
      title="Grupos de acesso"
      createTo={PainelPaths.GruposAcesso.Filtros(aplicacaoId).CriarGrupoAcesso}
      listItemTo={
        PainelPaths.GruposAcesso.Filtros(aplicacaoId).EditarGrupoAcesso
      }
      data={gruposAcesso}
      loading={loading}
      background={rotaAtual ? Colors.white : Colors.gray6}
      borderColor={rotaAtual ? Colors.gray6 : "unset"}
      render={(grupoAcesso) => (
        <>
          <h4>{grupoAcesso.nome}</h4>
          <p style={{ textAlign: "left", fontStyle: "italic" }}>
            {grupoAcesso.codigo}
          </p>
          <p style={{ textAlign: "left" }}>{grupoAcesso.descricao}</p>
          <p style={{ textAlign: "left" }}>
            {`${grupoAcesso.tenantNome}`}
          </p>
        </>
      )}
    >
      <Box px={2} pt={0.5}>
        <AsyncSelect
          placeholder="Selecione um tenant"
          noOptionsMessage={() => "Sem opções pré-definidas"}
          cacheOptions
          defaultOptions
          loadOptions={obterTenants}
          onChange={(e) => {
            selecionarTenantGrupoAcesso(e ? e.value : "");
          }}
        />
      </Box>
    </OptSidebarListContainer>
  );
};
