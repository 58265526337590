import { mdiDeleteOutline } from "@mdi/js";
import { OptBackdrop } from "@optsol/react";
import {
  OptConfirmationDialog,
  OptLoading,
  OptSideLayoutContent,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAplicacaoContext } from "../../../contexts/aplicacao/aplicacaoContext";
import { AplicacaoFormModel, SecretStringKey } from "../../../models/Aplicacao";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { useAplicacaoService } from "../../../services/aplicacao.service";
import { Colors } from "../../../shared/colors";
import { FormAplicacaoView } from "./FormAplicacaoView";

export interface FormAplicacaoLoading {
  carregar: boolean;
  excluir: boolean;
  salvar: boolean;
}

const INITIAL_APLICACAO_STATE: AplicacaoFormModel = {
  nome: "",
  descricao: "",
  identificadorClient: "",
  tipoAplicacaoId: "",
  url: "",
  acessos: [],
};

export const FormAplicacao = () => {
  const [aplicacao, setAplicacao] = useState<AplicacaoFormModel>(
    INITIAL_APLICACAO_STATE
  );
  const { aplicacaoId } = useParams<{
    aplicacaoId: string;
  }>();
  const [loading, setLoading] = useState<FormAplicacaoLoading>({
    carregar: false,
    excluir: false,
    salvar: false,
  });
  const [confirmandoExclusao, setConfirmandoExclusao] = useState(false);
  const [secretString, setSecretString] = useState<SecretStringKey>();

  const { listarAplicacoes } = useAplicacaoContext();
  const { obterAplicacao, salvarAplicacao, excluirAplicacao, gerarSecret } =
    useAplicacaoService();

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const estaEditando = !!aplicacaoId;

  async function carregar() {
    setLoading({ ...loading, carregar: true });

    let data = INITIAL_APLICACAO_STATE;

    if (aplicacaoId) {
      const aplicacaoResponseModel = await obterAplicacao(aplicacaoId);
      data = {
        acessos: aplicacaoResponseModel.acessos,
        descricao: aplicacaoResponseModel.descricao,
        id: aplicacaoResponseModel.id,
        identificadorClient: aplicacaoResponseModel.identificadorClient,
        nome: aplicacaoResponseModel.nome,
        url: aplicacaoResponseModel.url,
      };
    }

    setAplicacao(data);
    setLoading({ ...loading, carregar: false });
  }

  async function salvar(data: AplicacaoFormModel) {
    try {
      setLoading({ ...loading, salvar: true });

      data.id = aplicacao.id;
      const result = await salvarAplicacao(data);

      const operacao = estaEditando ? "editado" : "criado";
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });

      setLoading({ ...loading, salvar: false });

      listarAplicacoes();

      if (!estaEditando) {
        history.push(PainelPaths.Aplicacoes.EditarAplicacao(result.id));
      }

      carregar();
    } catch (error: any) {
      // enqueueSnackbar(error.message, { variant: "error" });
      enqueueSnackbar("Erro ao salvar registro.", { variant: "error" });
      carregar();
      setLoading({ ...loading, salvar: false });
    }
  }

  function confirmarExclusao() {
    setConfirmandoExclusao(true);
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  async function excluir() {
    try {
      setConfirmandoExclusao(false);
      setLoading({ ...loading, excluir: true });

      await excluirAplicacao(aplicacaoId);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      listarAplicacoes();

      setLoading({ ...loading, excluir: false });
      history.push(PainelPaths.Aplicacoes.Principal);
    } catch (error: any) {
      setLoading({ ...loading, excluir: false });
      // enqueueSnackbar(error.message, { variant: "error" });
      enqueueSnackbar("Erro ao excluir registro.", { variant: "error" });
    }
  }

  async function obterSecret() {
    try {
      const secretKey = await gerarSecret(aplicacaoId);
      setSecretString(secretKey);
    } catch (error) {
      console.error("Erro ao obter Secret", error);
    }
  }

  useEffect(() => {
    carregar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aplicacaoId]);

  return (
    <OptSideLayoutContent>
      <OptBackdrop open={loading.excluir || loading.salvar} />

      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir aplicação"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={excluir}
      >
        Deseja confirmar a exclusão da aplicação?
      </OptConfirmationDialog>

      {loading.carregar && <OptLoading size={50} />}

      {!loading.carregar && (
        <FormAplicacaoView
          data={aplicacao}
          salvar={salvar}
          estaEditando={estaEditando}
          remover={confirmarExclusao}
          loading={loading}
          obterSecret={obterSecret}
          secretString={secretString}
        />
      )}
    </OptSideLayoutContent>
  );
};
