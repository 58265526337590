import { Grid } from "@mui/material";
import styled from "styled-components";
import { BaseContainer } from "../../../shared/components/BaseContainer";

export const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
`;

export const FormGrid = styled(Grid)`
  width: 50%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Titulo = styled.span`
  font-size: 18px;
  line-height: 21px;
  margin-top: 30px;
  margin-bottom: 20px;
`;
