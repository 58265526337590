import { Drawer } from "@mui/material";
import styled from "styled-components";
import { Colors } from "../../../../shared/colors";
import AsyncSelect from "react-select/async";

export const SecaoForm = styled.div`
  border-radius: 8px;
  border: 1px solid ${Colors.gray6};
  padding: 20px;
  margin-bottom: auto;
`;

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    padding: 12px;
    width: 500px;
    display: flex;
    flex-direction: column;
  }
`;

StyledDrawer.defaultProps = {
  anchor: "right",
};

export const StyledAsyncSelect = styled(AsyncSelect)`
  width: 250px;
  margin-right: 10px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-left: 6px;

  & button {
    border: 1px solid ${Colors.gray6};
  }
`;
