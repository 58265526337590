import { GenericContext } from '../genericContext';
import { UsuarioActions } from './usuarioActions';
import { UsuarioState } from './usuarioState';

export type UsuarioDispatch = (action: UsuarioAction) => void;
export const USUARIO_INITIAL_DISPATCH = (action: UsuarioAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action);
};

export function UsuarioReducer(state: UsuarioState, action: UsuarioAction): UsuarioState {
  switch (action.type) {
    case UsuarioActions.ATUALIZAR_CONTADOR: {
      return { ...state, contador: { data: action.payload || 0, loading: false } };
    }
    default: {
      // eslint-disable-next-line
      throw new Error(`Ação não identificada: ${(action as any)!.type}`);
    }
  }
}

type UsuarioAction = GenericContext<UsuarioActions.ATUALIZAR_CONTADOR, number>;
