import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { FiltroGrupoAcessoSidebar } from "../../pages/grupos-acesso/FiltroGrupoAcessoSidebar/FiltroGrupoAcessoSidebar";
import { GruposAcessoListaRoutes } from "./GruposAcessoLista.routes";
import { PainelPaths } from "./Painel.routes";

export const GruposAcessoRoutes = () => {
  return (
    <OptSideLayoutPortalContainer>
      <FiltroGrupoAcessoSidebar />
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={PainelPaths.GruposAcesso.Principal}>
            {/* <h2>Principal de grupos de acesso</h2> */}
          </Route>
          <Route
            path={PainelPaths.GruposAcesso.Filtros().ListaGruposAcesso}
            component={GruposAcessoListaRoutes}
          />
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
