import React from "react";
import { Route, Switch } from "react-router-dom";
import { HomeContainer } from "../../pages/home/HomeContainer";
import { MeuPerfilContainer } from "../../pages/meu-perfil/MeuPerfilContainer";
import { AplicacoesListaRoutes } from "./AplicacoesLista.routes";
import { GruposAcessoRoutes } from "./GruposAcesso.routes";

export const PainelPaths = {
  Home: "/painel/",
  MeuPerfil: "/painel/meu-perfil",
  Aplicacoes: {
    Principal: "/painel/aplicacoes",
    CriarAplicacao: `/painel/aplicacoes/criar`,
    EditarAplicacao: (aplicacaoId = ":aplicacaoId") =>
      `/painel/aplicacoes/${aplicacaoId}`,
  },
  GruposAcesso: {
    Principal: "/painel/grupos-de-acesso",
    Filtros: (aplicacaoId = ":aplicacaoId") => ({
      Principal: "/painel/grupos-de-acesso/aplicacao",
      ListaGruposAcesso: `/painel/grupos-de-acesso/aplicacao/${aplicacaoId}`,
      CriarGrupoAcesso: `/painel/grupos-de-acesso/aplicacao/${aplicacaoId}/grupo-de-acesso/criar`,
      EditarGrupoAcesso: (grupoAcessoId = ":grupoAcessoId") =>
        `/painel/grupos-de-acesso/aplicacao/${aplicacaoId}/grupo-de-acesso/${grupoAcessoId}`,
    }),
  },
} as const;

export const PainelRoutes = (
  <Switch>
    <Route exact path={PainelPaths.Home} component={HomeContainer} />
    <Route exact path={PainelPaths.MeuPerfil} component={MeuPerfilContainer} />
    <Route
      path={PainelPaths.Aplicacoes.Principal}
      component={AplicacoesListaRoutes}
    />
    <Route
      path={PainelPaths.GruposAcesso.Principal}
      component={GruposAcessoRoutes}
    />
  </Switch>
);
