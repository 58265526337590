import { OptChip, OptSidebarListContainer } from "@optsol/react";
import React from "react";
import { Aplicacao } from "../../../models/Aplicacao";
import { PainelPaths } from "../../../routes/administracao/Painel.routes";
import { Colors } from "../../../shared/colors";
import { RotaAtualProps } from "../../../shared/types/RotaAtualProps";

interface Props extends RotaAtualProps {
  data: Aplicacao[];
  loading: boolean;
}

export const ListaAplicacoesSidebarView = ({
  data,
  loading,
  rotaAtual,
}: Props) => {
  return (
    <OptSidebarListContainer
      title="Aplicações"
      createTo={PainelPaths.Aplicacoes.CriarAplicacao}
      listItemTo={(id) => PainelPaths.Aplicacoes.EditarAplicacao(id)}
      data={data}
      loading={loading}
      background={rotaAtual ? Colors.white : Colors.gray6}
      borderColor={Colors.gray6}
      render={(aplicacao) => (
        <>
          <h4>{aplicacao.nome}</h4>
          <OptChip label={aplicacao.descricao} size="small" />

          <p>{aplicacao.descricao}</p>
        </>
      )}
    />
  );
};
