import React, { PropsWithChildren } from 'react';
import { AplicacaoProvider } from '../../contexts/aplicacao/aplicacaoContext';
import { AuthenticationProvider } from '../../contexts/authentication/authenticationContext';
import { GrupoAcessoProvider } from '../../contexts/grupoAcesso/grupoAcessoContext';
import { PerfilAcessoProvider } from '../../contexts/perfilAcesso/perfilAcessoContext';
import { UsuarioProvider } from '../../contexts/usuario/usuarioContext';
import { AppLayout } from './App.layout';

export const AppProviders = ({ children }: PropsWithChildren<{}>) => {
  return (
    <AuthenticationProvider>
      <PerfilAcessoProvider>
        <GrupoAcessoProvider>
          <UsuarioProvider>
            <AplicacaoProvider>
              <AppLayout children={children} />
            </AplicacaoProvider>
          </UsuarioProvider>
        </GrupoAcessoProvider>
      </PerfilAcessoProvider>
    </AuthenticationProvider>
  );
};
