import { Box } from "@mui/material";
import { OptActionButton, OptSelectionOption } from "@optsol/react";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import * as S from "./styles";
import { mdiPlus } from "@mdi/js";
import { Colors } from "../../../shared/colors";

interface Props {
  carregar: (termo: string) => Promise<OptSelectionOption[]>;
  salvar: (data: string[]) => void;
}

export const UsuarioSearch = ({ carregar, salvar }: Props) => {
  const [usuarios, setUsuarios] = useState<OptSelectionOption[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  function salvarHandler() {
    if (usuarios?.length > 0) {
      salvar(usuarios.map((x) => x.value));
    } else {
      enqueueSnackbar(`É necessário selecionar um usuário`, {
        variant: "warning",
      });
    }
  }

  return (
    <>
      <Box
        width="100%"
        display="flex"
        alignItems="start"
        justifyContent="space-between"
        flexWrap="wrap"
        px={2.2}
        pt={1.6}
        pb={7}
      >
        <p style={{ fontWeight: 500, fontSize: "18px", marginRight: "15px" }}>
          Usuários Associados
        </p>
        <Box display="flex">
          <S.StyledAsyncSelect
            placeholder="Selecione uma opção"
            noOptionsMessage={() => "Sem opções pré-definidas"}
            isMulti
            cacheOptions
            defaultOptions
            loadOptions={carregar}
            onChange={(e: any) => {
              setUsuarios(e as OptSelectionOption[]);
            }}
          />
          <S.ActionsContainer>
            <OptActionButton
              startIcon={{ path: mdiPlus, color: Colors.blue1 }}
              onClick={salvarHandler}
            >
              Adicionar
            </OptActionButton>
          </S.ActionsContainer>
        </Box>
      </Box>
    </>
  );
};
