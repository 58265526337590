import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import React, { createContext, useReducer } from 'react';
import { MsalConfig, pca } from '../../msal/config';
import { Claims } from '../../msal/UserInfo';
import { useAuthenticationService } from '../../services/authentication/authentication.service';
import { AuthenticationActions } from './authenticationActions';
import {
  AuthenticationDispatch,
  AuthenticationReducer,
  AUTHENTICATION_INITIAL_DISPATCH,
} from './authenticationReducer';
import { AuthenticationState, AUTHENTICATION_INITIAL_STATE } from './authenticationState';

const AuthenticationStateContext = createContext<AuthenticationState>(AUTHENTICATION_INITIAL_STATE);
const AuthenticationDispatchContext = createContext<AuthenticationDispatch>(AUTHENTICATION_INITIAL_DISPATCH);

type AuthenticationProps = { children: React.ReactNode };

function AuthenticationProvider({ children }: AuthenticationProps) {
  const [state, dispatch] = useReducer(AuthenticationReducer, AUTHENTICATION_INITIAL_STATE);

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{ scopes: MsalConfig.scopes }}>
        <AuthenticationStateContext.Provider value={state}>
          <AuthenticationDispatchContext.Provider value={dispatch}>{children}</AuthenticationDispatchContext.Provider>
        </AuthenticationStateContext.Provider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

function useAuthenticationContext() {
  const state = React.useContext(AuthenticationStateContext);
  const authenticationService = useAuthenticationService();

  if (state === undefined) {
    throw new Error('useAuthenticationState deve ser utilizando dentro de um AuthenticationProvider');
  }

  const dispatch = React.useContext(AuthenticationDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useAuthenticationDispatch deve ser utilizando dentro de um AuthenticationProvider');
  }

  const actions = AuthenticationActions;

  function hasAccess(access: string) {
    if (!state.userInfo.data) return false;
    return state.userInfo.data.claims.some((x: Claims) => x.value === access);
  }

  async function carregarUserInfo() {
    try {
      dispatch({ type: actions.LOADING_USER_INFO, payload: true });

      const result = await authenticationService.getUserInfo();

      dispatch({ type: actions.CARREGAR_USER_INFO, payload: result });
    } catch (error) {
      dispatch({ type: actions.CARREGAR_USER_INFO, payload: undefined });
      dispatch({ type: actions.LOADING_USER_INFO, payload: false });
      throw error;
    }
  }

  return { state, hasAccess, carregarUserInfo };
}

export { AuthenticationProvider, useAuthenticationContext };
