import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent,
} from "@optsol/react";
import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { FormGrupoAcesso } from "../../pages/grupos-acesso/FormGrupoAcesso/FormGrupoAcesso";
import { ListaGruposAcessoSidebar } from "../../pages/grupos-acesso/ListaGruposAcessoSidebar/ListaGruposAcessoSidebar";
import { PainelPaths } from "./Painel.routes";

export const GruposAcessoListaRoutes = () => {
  const match = useRouteMatch({
    path: [PainelPaths.GruposAcesso.Filtros().ListaGruposAcesso],
    exact: true,
  });

  const { aplicacaoId } = useParams<{ aplicacaoId: string }>();

  return (
    <OptSideLayoutPortalContainer>
      <ListaGruposAcessoSidebar
        rotaAtual={!!match?.isExact}
        aplicacaoId={aplicacaoId}
      />

      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={PainelPaths.GruposAcesso.Filtros().Principal}>
            <h1>Lista de grupos de acesso</h1>
          </Route>

          <Route
            exact
            path={PainelPaths.GruposAcesso.Filtros().CriarGrupoAcesso}
            component={FormGrupoAcesso}
          />
          <Route
            exact
            path={PainelPaths.GruposAcesso.Filtros().EditarGrupoAcesso()}
            component={FormGrupoAcesso}
          />
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
