import { Filtro } from "../../models/Filtro";
import { GrupoAcesso } from "../../models/GrupoAcesso";
import { LoadableData } from "../loadableData";

export interface GrupoAcessoState {
  filtros: LoadableData<Filtro[]>;
  lista: LoadableData<GrupoAcesso[]>;
  tenantId: string;
}

export const GRUPOACESSO_INITIAL_STATE: GrupoAcessoState = {
  filtros: { data: [], loading: false },
  lista: { data: [], loading: false },
  tenantId: "00000000-0000-0000-0000-000000000000",
};
