import React, { createContext, useReducer } from "react";
import { useGrupoAcessoService } from "../../services/grupoAcesso.service";
import { deepCopy } from "../../utils/functions";
import { GrupoAcessoActions } from "./grupoAcessoActions";
import {
  GrupoAcessoDispatch,
  GrupoAcessoReducer,
  GRUPOACESSO_INITIAL_DISPATCH,
} from "./grupoAcessoReducer";
import {
  GrupoAcessoState,
  GRUPOACESSO_INITIAL_STATE,
} from "./grupoAcessoState";

const GrupoAcessoStateContext = createContext<GrupoAcessoState>(
  GRUPOACESSO_INITIAL_STATE
);
const GrupoAcessoDispatchContext = createContext<GrupoAcessoDispatch>(
  GRUPOACESSO_INITIAL_DISPATCH
);

type GrupoAcessoProps = { children: React.ReactNode };

function GrupoAcessoProvider({ children }: GrupoAcessoProps) {
  const [state, dispatch] = useReducer(
    GrupoAcessoReducer,
    GRUPOACESSO_INITIAL_STATE
  );

  return (
    <GrupoAcessoStateContext.Provider value={state}>
      <GrupoAcessoDispatchContext.Provider value={dispatch}>
        {children}
      </GrupoAcessoDispatchContext.Provider>
    </GrupoAcessoStateContext.Provider>
  );
}

function useGrupoAcessoContext() {
  const state = React.useContext(GrupoAcessoStateContext);
  const grupoAcessoService = useGrupoAcessoService();

  if (state === undefined) {
    throw new Error(
      "useGrupoAcessoState deve ser utilizando dentro de um GrupoAcessoProvider"
    );
  }

  const dispatch = React.useContext(GrupoAcessoDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      "useGrupoAcessoDispatch deve ser utilizando dentro de um GrupoAcessoProvider"
    );
  }

  const actions = GrupoAcessoActions;

  async function decrementarFiltroAplicacao(aplicacaoId: string) {
    const novosFiltros = deepCopy(state.filtros);

    const filtroEncontrado = novosFiltros.data.find(
      (x) => x.id === aplicacaoId
    );

    if (filtroEncontrado) {
      filtroEncontrado.total -= 1;
      dispatch({ type: actions.CARREGAR_FILTROS, payload: novosFiltros.data });
    }
  }

  async function incrementarFiltroAplicacao(aplicacaoId: string) {
    const novosFiltros = deepCopy(state.filtros);

    const filtroEncontrado = novosFiltros.data.find(
      (x) => x.id === aplicacaoId
    );

    if (filtroEncontrado) {
      filtroEncontrado.total += 1;
      dispatch({ type: actions.CARREGAR_FILTROS, payload: novosFiltros.data });
    }
  }

  async function listarFiltrosAplicacao() {
    try {
      dispatch({ type: actions.LOADING_FILTROS, payload: true });

      const result = await grupoAcessoService.listarFiltrosAplicacao();

      dispatch({ type: actions.CARREGAR_FILTROS, payload: result });
    } catch (error) {
      dispatch({ type: actions.LOADING_FILTROS, payload: false });
      throw error;
    }
  }

  async function listarGruposAcessoPorAplicacao(
    aplicacaoId: string,
    tenantId: string
  ) {
    try {
      dispatch({ type: actions.LOADING_LISTA, payload: true });

      const result = await grupoAcessoService.listarGruposAcessoPorAplicacao(
        aplicacaoId,
        tenantId
      );

      dispatch({ type: actions.CARREGAR_LISTA, payload: result });
    } catch (error) {
      dispatch({ type: actions.LOADING_LISTA, payload: false });
      throw error;
    }
  }

  function resetarListaGrupoAcessoPorAplicacao() {
    dispatch({ type: actions.CARREGAR_LISTA, payload: [] });
  }

  function atualizarContextoTenantId(tenant: string) {
    dispatch({ type: actions.ATUALIZAR_TENANT, payload: tenant });
  }

  return {
    state,
    listarFiltrosAplicacao,
    incrementarFiltroAplicacao,
    decrementarFiltroAplicacao,
    listarGruposAcessoPorAplicacao,
    resetarListaGrupoAcessoPorAplicacao,
    atualizarContextoTenantId,
  };
}

export { GrupoAcessoProvider, useGrupoAcessoContext };
