import { OptActionToolbar, OptSideLayoutContent } from "@optsol/react";
import React from "react";
import { Home } from "./Home";

export const HomeContainer = () => {
  return (
    <OptSideLayoutContent>
      <OptActionToolbar title="Home" />

      <Home />
    </OptSideLayoutContent>
  );
};
