import { Filtro } from '../../models/Filtro';
import { PerfilAcesso } from '../../models/PerfilAcesso';
import { GenericContext } from '../genericContext';
import { PerfilAcessoActions } from './perfilAcessoActions';
import { PerfilAcessoState } from './perfilAcessoState';

export type PerfilAcessoDispatch = (action: PerfilAcessoAction) => void;
export const PERFILACESSO_INITIAL_DISPATCH = (action: PerfilAcessoAction): void => {
  throw Error('Dispatch not implemented. Action: ' + action);
};

export function PerfilAcessoReducer(state: PerfilAcessoState, action: PerfilAcessoAction): PerfilAcessoState {
  switch (action.type) {
    case PerfilAcessoActions.LOADING_FILTROS: {
      return { ...state, filtros: { ...state.filtros, loading: !!action.payload } };
    }
    case PerfilAcessoActions.CARREGAR_FILTROS: {
      return { ...state, filtros: { data: action.payload || [], loading: false } };
    }
    case PerfilAcessoActions.LOADING_LISTA: {
      return { ...state, lista: { ...state.lista, loading: !!action.payload } };
    }
    case PerfilAcessoActions.CARREGAR_LISTA: {
      return { ...state, lista: { data: action.payload || [], loading: false } };
    }
    default: {
      // eslint-disable-next-line
      throw new Error(`Ação não identificada: ${(action as any)!.type}`);
    }
  }
}

type PerfilAcessoAction =
  | GenericContext<PerfilAcessoActions.CARREGAR_FILTROS, Filtro[]>
  | GenericContext<PerfilAcessoActions.LOADING_FILTROS, boolean>
  | GenericContext<PerfilAcessoActions.CARREGAR_LISTA, PerfilAcesso[]>
  | GenericContext<PerfilAcessoActions.LOADING_LISTA, boolean>;
