import {
  OptActionToolbar,
  OptLoading,
  OptSideLayoutContent,
} from "@optsol/react";
import React, { useEffect, useState } from "react";
import { Perfil } from "../../models/Perfil";
import { FormMeuPerfil } from "./FormMeuPerfil";

const PERFIL_INITIAL_STATE: Perfil = {
  aplicacaoId: "",
  contato: "",
  email: "",
  grupoAcessoId: "",
  nome: "",
};

export const MeuPerfilContainer = () => {
  const [data] = useState<Perfil>(PERFIL_INITIAL_STATE);
  const [loading, setLoading] = useState(true);

  async function carregarMeuPerfil() {
    try {
      // const result = await obterPerfil('1');
      // setData(result);
    } catch {
      // TODO alerta
      console.error("Erro ao carregar perfil");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    carregarMeuPerfil();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptSideLayoutContent>
      <OptActionToolbar title="Meu perfil" />

      {loading && <OptLoading />}
      {!loading && <FormMeuPerfil data={data} />}
    </OptSideLayoutContent>
  );
};
