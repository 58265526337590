import { mdiAlertCircleOutline, mdiCheckCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { OptLoading } from "@optsol/react";
import React, { useEffect, useState } from "react";
import { AtivacaoUsuarioEmpresa } from "../../models/AtivacaoUsuarioEmpresa";
import { useUsuarioService } from "../../services/usuario.service";
import { Colors } from "../../shared/colors";
import { GlobalStyles } from "../../shared/styles/globalStyles";
import { RobotoFontStyles } from "../../shared/styles/robotoFont";
import * as S from "./styles";

export const AtivarUsuarioView = () => {
  const [dadosAtivacaoUsuario, setDadosAtivacaoUsuario] =
    useState<AtivacaoUsuarioEmpresa>();
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");

  const { ativarUsuario, reenviarLink } = useUsuarioService();

  async function obterConfirmacao() {
    setLoading(true);
    const params = new URLSearchParams(window.location.search);
    const codigo = params.get("code")!;
    setCode(codigo);
    try {
      const response = await ativarUsuario(codigo);
      setLoading(false);
      setDadosAtivacaoUsuario(response);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  function acessar() {
    if (dadosAtivacaoUsuario) {
      window.location.href = dadosAtivacaoUsuario.linkUrlRedirecionamento;
    }
  }

  async function reenviar() {
    setLoading(true);
    try {
      await reenviarLink(code);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  useEffect(() => {
    obterConfirmacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RobotoFontStyles />
      <GlobalStyles />

      <S.Body>
        <S.Borda />

        <S.Container>
          {loading && <OptLoading size={50} />}

          {!loading && dadosAtivacaoUsuario && (
            <>
              <h2>{dadosAtivacaoUsuario.nomeEmpresa}</h2>
              <S.TituloSecao>{dadosAtivacaoUsuario.nomeAplicao}</S.TituloSecao>
              <h4>{dadosAtivacaoUsuario.titulo}</h4>

              <Icon
                path={
                  dadosAtivacaoUsuario.sucesso
                    ? mdiCheckCircleOutline
                    : mdiAlertCircleOutline
                }
                size={10}
                color={
                  dadosAtivacaoUsuario.sucesso ? Colors.green2 : Colors.special
                }
              />

              <p>{dadosAtivacaoUsuario.mensagem}</p>

              {dadosAtivacaoUsuario.habilitarBotaoRedirecionar && (
                <S.Botao onClick={acessar}>Acessar</S.Botao>
              )}

              {dadosAtivacaoUsuario.habilitarBotaoReenviar && (
                <S.Botao onClick={reenviar}>Reenviar</S.Botao>
              )}
            </>
          )}

          {!loading && !dadosAtivacaoUsuario && <h3>Falha ao carregar</h3>}
        </S.Container>

        <S.Borda />
      </S.Body>
    </>
  );
};
