import { Filtro } from "../models/Filtro";
import { GrupoAcesso, GrupoAcessoFormModel } from "../models/GrupoAcesso";
import { InsertResponse } from "../shared/types/InsertResponse";
import { useApi } from "../utils/api";

export function useGrupoAcessoService() {
  const api = useApi({ semToken: false });

  async function listarGruposAcessoPorAplicacao(
    aplicacaoId: string,
    tenantId: string
  ): Promise<GrupoAcesso[]> {
    const result = await api.get<GrupoAcesso[]>(
      `/api/grupo-acesso/aplicacao/${aplicacaoId}/tenant/${tenantId}`
    );

    return result.data;
  }

  async function obterGrupoAcesso(id: string) {
    const result = await api.get<GrupoAcessoFormModel>(
      `/api/grupo-acesso/${id}`
    );

    return result.data;
  }

  async function salvarGrupoAcesso(data: GrupoAcessoFormModel) {
    if (data.id) {
      const result = await api.put<InsertResponse>(
        `/api/grupo-acesso/${data.id}`,
        data
      );
      return result.data;
    } else {
      const result = await api.post<InsertResponse>(`/api/grupo-acesso`, data);
      return result.data;
    }
  }

  async function excluirGrupoAcesso(id: string) {
    const result = await api.remove(`/api/grupo-acesso/${id}`);

    return result.data;
  }

  async function listarFiltrosAplicacao(): Promise<Filtro[]> {
    const result = await api.get<Filtro[]>(
      "/api/aplicacao/filtro-grupo-acesso"
    );

    return result.data;
  }

  async function salvarUsuarioGrupoAcesso(
    grupoAcessoId: string,
    usuarioIds: string[]
  ) {
    const response = await api.post(
      `api/grupo-acesso/${grupoAcessoId}/adicionar-usuario`,
      usuarioIds
    );
    return response.data;
  }

  async function removerUsuarioGrupoAcesso(
    grupoAcessoId: string,
    usuarioId: string
  ) {
    const result = await api.remove(
      `/api/grupo-acesso/${grupoAcessoId}/remover-usuario/${usuarioId}`
    );
    return result.data;
  }

  return {
    obterGrupoAcesso,
    listarGruposAcessoPorAplicacao,
    salvarGrupoAcesso,
    excluirGrupoAcesso,
    listarFiltrosAplicacao,
    salvarUsuarioGrupoAcesso,
    removerUsuarioGrupoAcesso,
  };
}
