import { IconButton, Tooltip } from "@mui/material";
import { mdiDelete, mdiLabel, mdiLaptop, mdiPlus, mdiViewQuilt } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Colors } from "../../../../shared/colors";
import {
  TreeNode,
  TreeNodeEndContainer,
  TreeNodeDescription,
} from "../../../../shared/components/Tree/styles";
import { TiposNodeAcessoControlado } from "./CriacaoNodeAcessoControladoDialog";

interface Props {
  id: string;
  label: string;
  codigoAlias?: string;
  descricao?: string;
  tipo: TiposNodeAcessoControlado;
  adicionar?: (parentId: string) => void;
  excluir: (id: string) => void;
  selecionavel?: boolean;
}

export const AcessoControladoNode = ({
  id,
  label,
  codigoAlias,
  descricao,
  tipo,
  adicionar,
  excluir,
  selecionavel,
}: Props) => {
  const folha = tipo === "acesso";

  let icon = mdiLaptop;
  let color: string = Colors.black;

  if (tipo === "grupoAcesso") {
    icon = mdiViewQuilt;
    color = Colors.blue1;
  } else if (tipo === "acesso") {
    icon = mdiLabel;
    color = Colors.green1;
  }

  return (
    <TreeNode>
      <Icon path={icon} size={0.8} color={color} />

      <span style={{ marginBottom: 4 }}>
        {codigoAlias ? (
          <>
            {`${label}: `}
            <strong style={{ fontSize: 14.5 }}>{codigoAlias}</strong>
          </>
        ) : (
          label
        )}
      </span>

      {descricao && <TreeNodeDescription>{descricao}</TreeNodeDescription>}

      <TreeNodeEndContainer>
        {!folha && !selecionavel && (
          <Tooltip title="Adicionar" placement="left">
            <IconButton onClick={() => adicionar && adicionar(id)}>
              <Icon path={mdiPlus} size={0.8} color={Colors.black} />
            </IconButton>
          </Tooltip>
        )}

        {!selecionavel && (
          <Tooltip title="Remover" placement="left">
            <IconButton onClick={() => excluir(id)}>
              <Icon path={mdiDelete} size={0.8} color={Colors.black} />
            </IconButton>
          </Tooltip>
        )}
      </TreeNodeEndContainer>
    </TreeNode>
  );
};
