import styled from "styled-components";
import { Colors } from "../../../../shared/colors";

export const SecaoForm = styled.div`
  border-radius: 8px;
  border: 1px solid ${Colors.gray6};
  padding: 20px;
`;

export const TituloSecao = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${Colors.black};
  margin-bottom: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-right: 2px;

  & button {
    border: 1px solid ${Colors.gray6};
  }
`;
