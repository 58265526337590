import styled from 'styled-components';
import { Colors } from '../../../shared/colors';
import { CommonButton } from '../../../shared/components/Buttons/CommonButton';

export const Body = styled.div`
  flex: 1;
  text-align: center;
  height: 100vh;
  color: ${Colors.gray2};
  display: flex;
  flex-direction: column;
`;

export const Borda = styled.div`
  background-color: ${Colors.primary};
  height: 20%;
`;

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 80px;
  overflow: auto;
`;

export const TituloSecao = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${Colors.black};
  margin-bottom: 24px;
`;

export const Botao = styled(CommonButton)`
  margin-top: 20px !important;
  background-color: ${Colors.primary} !important;
  color: ${Colors.white} !important;
  height: 60px !important;
  min-width: 140px !important;
`;
