import React, { createContext, useReducer } from "react";
import { useAplicacaoService } from "../../services/aplicacao.service";
import { AplicacaoActions } from "./aplicacaoActions";
import {
  AplicacaoDispatch,
  AplicacaoReducer,
  APLICACAO_INITIAL_DISPATCH,
} from "./aplicacaoReducer";
import { AplicacaoState, APLICACAO_INITIAL_STATE } from "./aplicacaoState";

const AplicacaoStateContext = createContext<AplicacaoState>(
  APLICACAO_INITIAL_STATE
);
const AplicacaoDispatchContext = createContext<AplicacaoDispatch>(
  APLICACAO_INITIAL_DISPATCH
);

type AplicacaoProps = { children: React.ReactNode };

function AplicacaoProvider({ children }: AplicacaoProps) {
  const [state, dispatch] = useReducer(
    AplicacaoReducer,
    APLICACAO_INITIAL_STATE
  );

  return (
    <AplicacaoStateContext.Provider value={state}>
      <AplicacaoDispatchContext.Provider value={dispatch}>
        {children}
      </AplicacaoDispatchContext.Provider>
    </AplicacaoStateContext.Provider>
  );
}

function useAplicacaoContext() {
  const state = React.useContext(AplicacaoStateContext);
  const aplicacaoService = useAplicacaoService();

  if (state === undefined) {
    throw new Error(
      "useAplicacaoState deve ser utilizando dentro de um AplicacaoProvider"
    );
  }

  const dispatch = React.useContext(AplicacaoDispatchContext);

  if (dispatch === undefined) {
    throw new Error(
      "useAplicacaoDispatch deve ser utilizando dentro de um AplicacaoProvider"
    );
  }

  const actions = AplicacaoActions;

  async function listarAplicacoes() {
    try {
      dispatch({ type: actions.LOADING_LISTA, payload: true });

      const result = await aplicacaoService.listarAplicacoes();

      dispatch({ type: actions.CARREGAR_LISTA, payload: result });
    } catch (error) {
      dispatch({ type: actions.LOADING_LISTA, payload: false });
      throw error;
    }
  }

  return { state, listarAplicacoes };
}

export { AplicacaoProvider, useAplicacaoContext };
