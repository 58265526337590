import { Filtro } from "../../models/Filtro";
import { GrupoAcesso } from "../../models/GrupoAcesso";
import { GenericContext } from "../genericContext";
import { GrupoAcessoActions } from "./grupoAcessoActions";
import { GrupoAcessoState } from "./grupoAcessoState";

export type GrupoAcessoDispatch = (action: GrupoAcessoAction) => void;
export const GRUPOACESSO_INITIAL_DISPATCH = (
  action: GrupoAcessoAction
): void => {
  throw Error("Dispatch not implemented. Action: " + action);
};

export function GrupoAcessoReducer(
  state: GrupoAcessoState,
  action: GrupoAcessoAction
): GrupoAcessoState {
  switch (action.type) {
    case GrupoAcessoActions.LOADING_FILTROS: {
      return {
        ...state,
        filtros: { ...state.filtros, loading: !!action.payload },
      };
    }
    case GrupoAcessoActions.CARREGAR_FILTROS: {
      return {
        ...state,
        filtros: { data: action.payload || [], loading: false },
      };
    }
    case GrupoAcessoActions.LOADING_LISTA: {
      return { ...state, lista: { ...state.lista, loading: !!action.payload } };
    }
    case GrupoAcessoActions.CARREGAR_LISTA: {
      return {
        ...state,
        lista: { data: action.payload || [], loading: false },
      };
    }
    case GrupoAcessoActions.ATUALIZAR_TENANT: {
      return {
        ...state,
        tenantId: action.payload ?? "00000000-0000-0000-0000-000000000000",
      };
    }
    default: {
      // eslint-disable-next-line
      throw new Error(`Ação não identificada: ${(action as any)!.type}`);
    }
  }
}

type GrupoAcessoAction =
  | GenericContext<GrupoAcessoActions.CARREGAR_FILTROS, Filtro[]>
  | GenericContext<GrupoAcessoActions.LOADING_FILTROS, boolean>
  | GenericContext<GrupoAcessoActions.CARREGAR_LISTA, GrupoAcesso[]>
  | GenericContext<GrupoAcessoActions.LOADING_LISTA, boolean>
  | GenericContext<GrupoAcessoActions.ATUALIZAR_TENANT, string>;
