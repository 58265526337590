import { OptLayoutProvider } from '@optsol/react';
import React, { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { theme } from '../../shared/theme';

export const AppLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <OptLayoutProvider theme={theme} noRouter>
      <BrowserRouter>{children}</BrowserRouter>
    </OptLayoutProvider>
  );
};
